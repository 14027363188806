import { useContext, useState, useEffect, useRef } from "react";
import "./NewBasslineButton.css";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import { InputContext, IInputContext } from "../../contexts/InputContext";
import { OutputContext, IOutputContext } from "../../contexts/OutputContext";
import { IRequestContext, RequestContext } from "../../contexts/RequestContext";
import { useKeyPress } from "../../hooks/UseKeyPress";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { theme } from "../../constants/theme";
import loadingGIF from "../../assets/bassnet-animation.gif";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";

const GENERATION_BLOCKED_COUNTDOWN =
  process.env.REACT_APP_BASSLINE_GENERATION_BLOCKED_COUNTDOWN;

const NewBasslineButton = () => {
  const { getUnadded, inputs, inputSelection } = useContext(InputContext) as IInputContext;
  const { isTextInputMode } = useContext(OutputContext) as IOutputContext;
  const {
    setNbPendingRequests,
    nbPendingRequests,
    getInputsBuffer,
    setGeneratingBasslines,
    generatingBasslines,
    downloadGenerated,
  } = useContext(RequestContext) as IRequestContext;
  const { currentModel } = useContext(ModelContext) as ModelContextProps;
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
      setParentHeight(parentRef.current.offsetHeight);
    }
  }, [parentRef]);

  useKeyPress(
    ["Enter"],
    () => {
      if (isTextInputMode) return;
      generateNewBassline();
    },
    [13]
  );

  // Countdown timer
  const [isCounting, setIsCounting] = useState(false);
  const generationBlocked = Number(GENERATION_BLOCKED_COUNTDOWN);
  const [counter, setCounter] = useState(generationBlocked);
  const [isEmptyInputs, setIsEmptyInputs] = useState(true);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    // check if there are any unadded inputs
    const unadded = getUnadded();
    if (unadded.length > 0) {
      setIsEmptyInputs(false);
    } else {
      setIsEmptyInputs(true);
    }
  }, [inputs, setIsEmptyInputs, getUnadded]);

  useEffect(() => {
    if (!isCounting) return;
    counter > -0.5 && setTimeout(() => setCounter(counter - 0.1), 100);
    setProgress((counter / generationBlocked) * 100);
  }, [isCounting, counter, generationBlocked]);

  useEffect(() => {
    if (counter <= -0.5) {
      setIsCounting(false);
      setCounter(generationBlocked);
      setProgress(100);
    }
  }, [counter, generationBlocked]);

  const generateNewBassline = async () => {
    if (getUnadded().length > 0 && nbPendingRequests < 6) {
      setNbPendingRequests((prev) => prev + 1);
      setIsCounting(true);
      let buffer = await getInputsBuffer();
      setGeneratingBasslines([
        ...generatingBasslines,
        { isGenerating: false, queuePos: -1 },
      ]);
      let data = await currentModel.fetchGenerate(buffer, undefined, undefined, undefined, undefined, inputSelection ? Math.min(inputSelection[0], inputSelection[1]) : undefined);
      console.log("data", data);
      await downloadGenerated(data);
    }
  };

  return (
    <div className="generate-button-border-container">
      <div
        onClick={generateNewBassline}
        className="generate-button-main-container"
        ref={parentRef}
      >
        <div className="generate-button-background-border">
          <div
            className="generate-button-background-container"
            style={nbPendingRequests >= 6 ? { opacity: "0.2" } : {}}
          >
            {isCounting ? (
              <img
                src={loadingGIF}
                alt="loading-gif"
                height="60vw"
                width="60vw"
                style={{
                  animation: "fadeIn 1s ease-in-out",
                  marginBottom: "-2%",
                }}
              />
            ) : (
              <div className="generate-button-icon-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="bassnet-logo"
                  icon={require(`../../assets/${currentModel.icon}`)}
                  height="auto"
                />
              </div>
            )}
            {currentModel.generateButtonText ?? "GENERATE"}
          </div>
        </div>
      </div>
      {(isCounting || isEmptyInputs) && (
        <div
          className="generate-button-progress-bar-container"
          style={{ width: parentHeight, height: parentWidth }}
        >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                borderRadius: "150px",
                backgroundColor: isEmptyInputs ? theme.palette.darkGrey : "",
              }}
              >
              {!isEmptyInputs && (
              <Grid
                xs
                item
                sx={{
                  borderRadius: "150px",
                  position: "relative",
                  display: "block",
                  height: "100%",
                  width: "100%",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    borderRadius: "150px",
                    height: "100%",
                    width: "100%",
                    backgroundColor: theme.palette.darkGrey,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: theme.palette.darkSalmon,
                    },
                  }}
                />
              </Grid>
            )}
            </Grid>
        </div>
      )}
    </div>
  );
};

export default NewBasslineButton;
