import { FC } from "react";
import "./RotarySliderGauge.css";

import CircularSlider from "react-circular-slider-svg";

interface RotarySliderGaugeProps {
  value: number;
  onChange: (value: number) => void;
  size: number;
  min?: number;
  max?: number;
}

const RotarySliderGauge: FC<RotarySliderGaugeProps> = ({ value, onChange, size, min, max }) => {
  // set gauge size depending on window width
  const gaugeSize = window.innerWidth <= 1600 ? 122 : 145;

  return (
    <div className="rotary-slider-gauge" >
      <CircularSlider
        size={gaugeSize * size}
        trackWidth={8}
        minValue={min ?? 0}
        maxValue={max ?? 2}
        startAngle={40}
        endAngle={320}
        angleType={{
          direction: "cw",
          axis: "-y"
        }}
        handle1={{
          value: value,
        }}
        arcColor="#ffffff"
        arcBackgroundColor="#424242"
      />
    </div>
  );
};

export default RotarySliderGauge;
