import { FC } from "react";
import "./BigRoundedButton.css";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect"

interface BigRoundedButtonProps {
  icon: any;
  text: string;
  width?: string;
  height?: string;
  onClick: () => void;
  isSvg: boolean;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  padding?: string;
  boxShadow?: boolean;
  isLoading: boolean;
}

const BigRoundedButton: FC<BigRoundedButtonProps> = ({
  icon,
  text,
  width,
  height,
  onClick,
  isSvg,
  backgroundColor,
  fontSize,
  fontWeight,
  margin,
  padding,
  boxShadow,
  isLoading,
}) => {
  return (
    <div
      onClick={isLoading ? () => null : () => onClick()}
      className="big-rounded-button-main-container"
      style={{
        width: width ? width : "100%",
        height: height ? height : "100%",
        backgroundColor: backgroundColor && backgroundColor,
        fontSize: fontSize ? fontSize : "inherit",
        fontWeight: fontWeight ? fontWeight : "inherit",
        margin: margin && margin,
        padding: padding && padding,
      }}
    >
      {isLoading ? (
        <>
          <CircularProgress
            style={{ color: "white", width: "2em", height: "2em" }}
          />
        </>
      ) : (
        <>
          {isSvg ? <img alt="icon" src={icon} /> : <>{icon}</>}
          {!isMobile && (
            <span
              className="big-rounded-button-text"
              style={{
                marginLeft: "0.3vw",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </span>
          )}
        </>
      )}
    </div>
  );
}

export default BigRoundedButton;
