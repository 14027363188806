import { useState, useEffect } from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MainContainer from "./components/MainContainer/MainContainer";
import LegalDialog, { CheckLegal } from "./components/legaldialog/legalDialog";
import { InputContextProvider } from "./contexts/InputContext";
import { RequestContextProvider } from "./contexts/RequestContext";
import { OutputContextProvider } from "./contexts/OutputContext";
import * as Tone from "tone";
import { PlayerContextProvider } from "./contexts/PlayerContext";
import { ModelContextProvider } from "./contexts/ModelContext";

const queryClient = new QueryClient();

function App() {
  const [agreed, setAgreed] = useState<boolean>(false);
  Tone.start();

  useEffect(() => {
    if (!process.env.REACT_APP_SECRET_CODE) setAgreed(CheckLegal());
  }, [agreed]);

  return (
    <QueryClientProvider client={queryClient}>
      <InputContextProvider>
        <ModelContextProvider>
          <PlayerContextProvider>
            <OutputContextProvider>
              <RequestContextProvider>
                <div className="App disable-text-selection">
                  <Header state="state" />
                  {!agreed ? (
                    <LegalDialog
                      onChange={() => {
                        if (process.env.REACT_APP_SECRET_CODE) setAgreed(true);
                        else setAgreed(CheckLegal());
                      }}
                    />
                  ) : (
                    <MainContainer />
                  )}
                  <Footer />
                </div>
              </RequestContextProvider>
            </OutputContextProvider>
          </PlayerContextProvider>
        </ModelContextProvider>
      </InputContextProvider>
    </QueryClientProvider>
  );
}

export default App;
