import { FC, useContext, useState } from "react";
import "./MainContainer.css";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import DragAndDropContainer from "../DragAndDrop/DragAndDropContainer";
import NewBasslineButton from "../Buttons/NewBasslineButton";
import BasslinesContainer from "../Basslines/BasslinesContainer";
import GeneratedIcon from "../../assets/bassnet-generated-icon.svg";
import TracksListContainer from "../TracksList/TracksListContainer";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";
import React from "react";
import { ControlComponentMap } from "../../models/IModel";
import { IRequestContext, RequestContext } from "../../contexts/RequestContext";
import InpaintModal from "../InpaintModal/InpaintModal";
import GenericModal from "../UI/GenericModal";
import { IInputContext, InputContext } from '../../contexts/InputContext';

interface MainContainerProps {}

const MainContainer: FC<MainContainerProps> = () => {
  const [invertedMode, setInvertedMode] = useState(false);
  const { currentModel } = React.useContext(ModelContext) as ModelContextProps;
  const {
    inpaintingItem,
    setInpaintingItem,
    setGeneratingBasslines,
    generatingBasslines,
    downloadGenerated,
    convertAudioBufferSampleRate,
    getInputsBuffer
  } = React.useContext(RequestContext) as IRequestContext;
  const {getUnadded} = useContext(InputContext) as IInputContext;

  const handleInpaint = async () => {
    let unadded = getUnadded();
    if (inpaintingItem && inpaintingItem.selection && unadded.length > 0) {
      let sel = inpaintingItem.selection;
      let audioBuffer = await convertAudioBufferSampleRate(
        inpaintingItem.audioBuffer
      );
      let inpBuffer = await getInputsBuffer();
      setInpaintingItem(undefined);
      setGeneratingBasslines([
        ...generatingBasslines,
        { isGenerating: false, queuePos: -1 },
      ]);
      const res = await currentModel.fetchGenerate(
        inpBuffer,
        audioBuffer,
        sel,
        invertedMode,
        (inpaintingItem.originalAudioBuffer.duration !== inpaintingItem.audioBuffer.duration) ? inpaintingItem.originalAudioBuffer.duration : undefined,
      );
      console.log("res", res);
      await downloadGenerated(res);
    }
  };

  return (
    <>
      {isMobile ? (
        <motion.div className="main-container-main-container"></motion.div>
      ) : (
        <motion.div className="main-container-main-container">
          {inpaintingItem && <div className="overlay"></div>}
          {inpaintingItem && (
            <GenericModal
              element={
                <InpaintModal
                  invertedMode={invertedMode}
                  setInvertedMode={setInvertedMode}
                  item={inpaintingItem}
                  onGenerate={handleInpaint}
                  onClose={() => setInpaintingItem(undefined)}
                />
              }
              open={inpaintingItem !== undefined}
              handleModalClose={() => setInpaintingItem(undefined)}
            />
          )}
          <div className="main-container-pannel">
            <div className="main-container-pannel-controls-basslines-container">
              <div className="main-container-controls-container">
                <div className="controls-container-dd-new-bassline-container">
                  <DragAndDropContainer />
                  <NewBasslineButton />
                </div>
                <div className="controls-container-transform-guidance-container">
                  {currentModel.controls.map((control, index) => {
                    const ControlComponent = ControlComponentMap[control.type]; // Get the corresponding component
                    if (ControlComponent) {
                      return (
                        <ControlComponent key={index} {...control.props} />
                      );
                    } else {
                      console.error(`Unknown control type: ${control.type}`);
                      return null;
                    }
                  })}
                  {/* {currentModel.controls.map((control, index) => {
                                        if (control.type === "Horizontal") {
                                            return (<TransformContainer key={index}/>);
                                        }
                                        if (control.type === "Rotary") return <GuidanceContainer key={index}/>
                                    })}
                                    {/* <TransformContainer />
                                    <GuidanceContainer /> */}
                </div>
              </div>
              <div className="main-container-basslines-container">
                <BasslinesContainer />
              </div>
            </div>
            <div className="main-container-audio-inputs">
              <TracksListContainer />
              <TracksListContainer
                isGenerated={true}
                icon={GeneratedIcon}
                title={
                  currentModel.generatedContainerName ?? "Generated Audios"
                }
              />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default MainContainer;
