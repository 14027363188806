import { createContext, useCallback, useState } from 'react';

export interface IInputContext {
    inputs: IInput[],
    addInput: (file: File) => Promise<IInput | null>,
    setInputs: React.Dispatch<React.SetStateAction<IInput[]>>,
    removeInput: (index: number) => void,
    addInputToAdded: (index: number) => void,
    removeInputFromAdded: (index: number) => void,
    modifyInputTitle: (index: number, newTitle: string) => void,
    getAdded: () => IInput[],
    getUnadded: () => IInput[],
    getInputIndex: (input: IInput) => number,
    rescaleBuffer: (input: IInput, maxDuration: number, maxSampleRate: number, offset?: number) => IInput,
    modifyInputSelection: (input: IInput, selection: number[]) => void;
    selectedInput: IInput | null;
    setSelectedInput: React.Dispatch<React.SetStateAction<IInput | null>>;
    inputSelection: number[] | undefined;
    setInputSelection: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

export const InputContext = createContext<null | IInputContext>(null);

export interface IInput {
    title: string,
    audioBuffer: AudioBuffer,
    originalAudioBuffer: AudioBuffer,
    duration: number,
    added: boolean,
    color: string,
    selection?: number[];
    isMuted: boolean;
    isSoloed: boolean;
    offset?: number;
    godMode: boolean;
}

interface InputContextProviderProps {
  children: React.ReactNode;
}

export const InputContextProvider = ({ children }: InputContextProviderProps) => {
  const [inputSelection, setInputSelection] = useState<number[] | undefined>(undefined);
  const [inputs, setInputs] = useState<IInput[]>([]);
  const [selectedInput, setSelectedInput] = useState<IInput | null>(null);

  const rescaleBuffer = useCallback((input: IInput, maxDuration : number, maxSampleRate : number, offset?: number) => {
    const newBuffer = new AudioBuffer({
      length: maxDuration * maxSampleRate,
      numberOfChannels: input.audioBuffer.numberOfChannels,
      sampleRate: maxSampleRate,
    });
    for (let channel = 0; channel < input.audioBuffer.numberOfChannels; channel++) {
      const oldData = input.audioBuffer.getChannelData(channel);
      newBuffer.copyToChannel(oldData, channel, offset ? offset * maxSampleRate : 0);
    }
    input.audioBuffer = newBuffer;
    input.offset = 0;
    return input;
  }, []);

  const addInput = async (file: File): Promise<IInput | null> => {
    const audioContext = new AudioContext();
    try {
      const audioData = await file.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const newInput: IInput = {
        title: file.name,
        audioBuffer: audioBuffer,
        originalAudioBuffer: audioBuffer,
        duration: audioBuffer.duration,
        added: false,
        color: "#DB4500",
        isMuted: false,
        isSoloed: false,
        godMode: true,
      };
      setInputs([...inputs, newInput]);
      return newInput;
    } catch (error) {
      console.error('Error loading audio file:', error);
      return null;
    }
  };

  const getInputIndex = (input: IInput) => {
    const index = inputs.indexOf(input);
    return index;
  }

  const getUnadded = () => {
    const unaddedInputs: IInput[] = inputs.filter((input) => input.added === false);
    return unaddedInputs;
  }

  const getAdded = () => {
    const added: IInput[] = inputs.filter((input) => input.added);
    return added;
  }

  const modifyInputTitle = (index: number, newTitle: string) => {
    const newInputs = [...inputs];
    newInputs[index].title = newTitle;
    setInputs(newInputs);
  }

  const removeInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const addInputToAdded = (index: number) => {
    const newInputs = [...inputs];
    newInputs[index].added = true;
    setInputs(newInputs);
  };

  const removeInputFromAdded = (index: number) => {
    const newInputs = [...inputs];
    newInputs[index].added = false;
    setInputs(newInputs);
  };

  const modifyInputSelection = (input: IInput, selection: number[]) => {
    let temp = [...inputs];
    const found = temp.find(elem => elem === input);
    if (found) {
      found.selection = selection;
      setInputs(temp);
    }
  }

  const contextValue = {
    inputs,
    addInput,
    setInputs,
    removeInput,
    addInputToAdded,
    removeInputFromAdded,
    modifyInputTitle,
    getAdded,
    getUnadded,
    getInputIndex,
    rescaleBuffer,
    modifyInputSelection,
    selectedInput,
    setSelectedInput,
    inputSelection,
    setInputSelection,
  };

  return (
    <InputContext.Provider value={contextValue}>
      {children}
    </InputContext.Provider>
  );
};
