import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./BasslineElement.css";
import { theme } from "../../constants/theme";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import playIcon from "../../assets/player/play-icon.svg";
import pauseIcon from "../../assets/player/pause-icon.svg";
import downloadIcon from "../../assets/player/download-icon.svg";
import unfold from "../../assets/bassline/unfold-icon.svg";
import fold from "../../assets/bassline/fold-icon.svg";
import removeIcon from "../../assets/bassline/remove-icon.svg";
import addArrowIcon from "../../assets/bassline/add-arrow-icon.svg";
import WaveformContainer from "../Waveform/WaveformContainer";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  IOutput,
  IOutputContext,
  OutputContext,
} from "../../contexts/OutputContext";
import useAudioPlayer from "../../tools/AudioPlayer";
import { computeTime } from "../../tools/computeTime";
import socket from "../../services/socketService";
import {
  RequestContext,
  IRequestContext,
  IGeneratingBassline,
} from "../../contexts/RequestContext";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";
import { PlayerContext, IPlayerContext } from "../../contexts/PlayerContext";

interface CustomTooltipProps extends TooltipProps {
  width?: number;
  backgroundColor?: string;
}

export const CustomTooltip = styled(
  ({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ width, backgroundColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor ?? theme.palette.lightGrey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: width || 500,
    backgroundColor: backgroundColor ?? theme.palette.lightGrey,
  },
}));

interface BasslineElementProps {
  item: IOutput | IGeneratingBassline;
  index: number;
  progress: number;
}

const BasslineElement: FC<BasslineElementProps> = ({
  item,
  index,
  progress,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isFolded, setIsFolded] = useState<boolean>(true);
  const [isElementHovered, setIsElementHovered] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    if ((item as IGeneratingBassline).isGenerating === false) {
      setIsFolded(false);
    } else setIsFolded(true);
  }, [item]);

  const {
    removeOutput,
    addOutputToInput,
    convertAudioBufferToBlobAndDownload,
  } = useContext(OutputContext) as IOutputContext;

  const { setGeneratingBasslines, setNbPendingRequests, nbPendingRequests } =
    useContext(RequestContext) as IRequestContext;
  const { currentModel } = useContext(ModelContext) as ModelContextProps;
  const { pauseAllPlayers } = useContext(PlayerContext) as IPlayerContext;

  const { playAudio, stopAudio } = useAudioPlayer(
    (item as IOutput).title !== undefined
      ? {
          audioBuffer: (item as IOutput).originalAudioBuffer,
          setCurrentTime,
          currentTime,
          onAudioEnd: () => setIsPlaying(false),
        }
      : {
          audioBuffer: null,
          setCurrentTime: () => {},
          currentTime: 0,
          onAudioEnd: () => {},
        }
  );

  const basslineDetailsStyle = useMemo(
    () => ({
      fontStyle: "italic",
      fontSize: "0.7vw",
      fontWeight: 300,
    }),
    []
  );

  const getGeneratedDate = (time: string) => {
    const date = new Date(Number(time) * 1000);
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return (
      hours.toString() +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      ampm
    );
  };

  const CustomTooltipContent = useCallback(
    () => (
      <div className="bassline-tooltip-container">
        {(item as IOutput).title !== undefined && (
          <>
            <div className="bassline-tooltip-title">
              <ErrorOutlineIcon sx={{ color: "white", fontSize: "large" }} />
              <div>Infos</div>
            </div>
            <div className="bassline-infos">
              <div className="bassline-file-infos">
                <Typography style={basslineDetailsStyle}>
                  Generated at {getGeneratedDate((item as IOutput).generatedAt)}
                </Typography>
              </div>
              <div className="bassline-generation-infos">
                {(item as IOutput).controls.map((control, index) => (
                  <Typography key={index} style={basslineDetailsStyle}>
                    {currentModel.controls[index].name}: {control.toFixed(2)}
                  </Typography>
                ))}
                <Typography style={basslineDetailsStyle}>
                  model: {(item as IOutput).model}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    ),
    [item, basslineDetailsStyle, currentModel.controls]
  );

  const toggleFold = () => {
    if ((item as IGeneratingBassline).isGenerating === false) return;
    const value = isFolded;
    setIsFolded(!value);
  };

  useEffect(() => {
    if ((item as IGeneratingBassline).isGenerating !== undefined) return;
    if (isPlaying) {
      playAudio();
    } else {
      stopAudio();
    }
  }, [isPlaying, item]);

  const handleDownload = () => {
    if ((item as IGeneratingBassline).isGenerating !== undefined) return;
    convertAudioBufferToBlobAndDownload(
      (item as IOutput).originalAudioBuffer,
      (item as IOutput).title
    );
  };

  return (
    <div className="bassline-element-border-container">
      <div
        className="bassline-element-main-container"
        onMouseEnter={() => setIsElementHovered(true)}
        onMouseLeave={() => setIsElementHovered(false)}
        style={{
          backgroundColor:
            isElementHovered ||
            isFolded ||
            (item as IGeneratingBassline).isGenerating !== undefined
              ? theme.palette.black
              : theme.palette.darkGrey,
        }}
      >
        <div className="bassline-element-main">
          {isElementHovered || isFolded ? (
            <div
              className="bassline-element-remove-icon"
              onClick={() => {
                pauseAllPlayers();
                if ((item as IOutput).title !== undefined) removeOutput(index);
                else {
                  socket.emit("cancel", (item as IGeneratingBassline).queuePos);
                  setGeneratingBasslines((prev) => {
                    const temp = [...prev];
                    temp.splice(index, 1);
                    return [...temp];
                  });
                  setNbPendingRequests(nbPendingRequests - 1);
                }
              }}
            >
              <SimpleSVGComponent
                icon={removeIcon}
                alt="remove-icon"
                cursor={true}
                height="0.7rem"
                width="0.7rem"
              />
            </div>
          ) : null}
          <div className="bassline-player-download-container">
            <div
              className="bassline-player-icon"
              // set is playing to !isPlaying
              onClick={() => {
                setIsPlaying(!isPlaying);
              }}
              style={{
                backgroundColor: theme.palette.lightGrey,
                borderRadius: "50%",
                width: "1.4vw",
                height: "1.4vw",
                boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {isPlaying ? (
                <SimpleSVGComponent
                  icon={pauseIcon}
                  alt="pause-icon"
                  cursor={true}
                  height="0.6vw"
                  width="0.6vw"
                />
              ) : (
                <SimpleSVGComponent
                  icon={playIcon}
                  alt="play-icon"
                  cursor={true}
                  height="0.6vw"
                  width="0.6vw"
                />
              )}
            </div>
            {(item as IOutput).godMode && <div
              className="bassline-download-icon"
              onClick={handleDownload}
              style={{
                backgroundColor: theme.palette.lightGrey,
                borderRadius: "50%",
                width: "1.4vw",
                height: "1.4vw",
                boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <SimpleSVGComponent
                icon={downloadIcon}
                alt="download-icon"
                cursor={true}
                height="1vw"
                width="1vw"
              />
            </div>}
          </div>
          <div className="bassline-middle-infos">
            <div className="bassline-title">
              {(item as IOutput).title !== undefined
                ? (item as IOutput).title
                : (item as IGeneratingBassline).isGenerating
                  ? "Generating..."
                  : `${(item as IGeneratingBassline).queuePos} in queue`}
            </div>
            <div className="bassline-folding-icon" onClick={toggleFold}>
              {isFolded ? (
                <SimpleSVGComponent
                  icon={unfold}
                  alt="unfold-icon"
                  cursor={true}
                  height="0.7vw"
                  width="0.7vw"
                />
              ) : (
                <SimpleSVGComponent
                  icon={fold}
                  alt="fold-icon"
                  cursor={true}
                  height="0.7vw"
                  width="0.7vw"
                />
              )}
            </div>
            <div>|</div>
            <div className="bassline-duration-text">
              {computeTime(
                (item as IGeneratingBassline).isGenerating !== undefined
                  ? 0
                  : (item as IOutput).duration
              )}
            </div>
            {(item as IOutput).title !== undefined && <div>|</div>}
            {(item as IOutput).title !== undefined && (
              <CustomTooltip title={<CustomTooltipContent />} arrow>
                <MoreHorizIcon sx={{ cursor: "pointer", fontSize: "1vw" }} />
              </CustomTooltip>
            )}
          </div>
        </div>
        {(isElementHovered || isFolded) &&
        (item as IOutput).title !== undefined ? (
          <div
            className="bassline-folded-add-button-container"
            onClick={() => {
              pauseAllPlayers();
              addOutputToInput(index);
            }}
          >
            <div className="bassline-folded-add-button-title">Add</div>
            <div className="bassline-folded-add-button-icon">
              <SimpleSVGComponent
                icon={addArrowIcon}
                alt="add-arrow-icon"
                cursor={true}
                height="0.6vw"
                width="0.6vw"
              />
            </div>
          </div>
        ) : null}
      </div>
      {isFolded && (
        <div
          className="bassline-waveform-container"
          style={{
            height: (item as IOutput).title !== undefined ? "2vw" : "",
            width: (item as IOutput).title !== undefined ? "99.3%" : "",
          }}
        >
          {(item as IOutput).title !== undefined ? (
            <WaveformContainer
              audioBuffer={(item as IOutput).originalAudioBuffer}
              drawAmplitudeLines={false}
              color={(item as IOutput).color}
              currentTime={currentTime}
              onChangeSliderValue={(value: number) => {
                setCurrentTime(
                  (value * (item as IOutput).originalAudioBuffer.duration) / 100
                );
                if (isPlaying) setIsPlaying(false);
              }}
              isBasslineElement={true}
              backgroundColor="black"
            />
          ) : (
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                borderRadius: "0.5vw",
              }}
            >
              <Grid xs item sx={{ borderRadius: "0.5vw" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    borderRadius: "0.5vw",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    color="primary"
                    sx={{
                      borderRadius: "0.5vw",
                      height: "1vw",
                      width: "100%",
                      backgroundColor: theme.palette.black,
                      "& .MuiLinearProgress-bar1Determinate": {
                        backgroundImage:
                          "linear-gradient(-45deg, rgba(255,255,255,0.125) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.125) 50%, rgba(255,255,255,0.125) 75%, transparent 75%, transparent)",
                        backgroundSize: "35px 35px",
                        backgroundColor: theme.palette.darkGrey,
                        overflow: "hidden",
                        borderRadius: "0.5vw",
                        animation:
                          "cssProgressActive 2s linear 0s infinite normal none running",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};

export default BasslineElement;
