import "./Footer.css";
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="footer-container">
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && <div className="copyright-container">Copyright © 2023 Sony Group Corporation. All rights reserved. </div>}
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && <div className="links-container">
          <a href="https://techhub.developer.sony.com/contact-us" target="_blank" rel="noreferrer">
            Contact us
          </a>
          <a href="https://techhub.developer.sony.com/about/" target="_blank" rel="noreferrer">
            About
          </a>
          <a href="https://techhub.developer.sony.com/legal-information" target="_blank" rel="noreferrer">
            Legal
          </a>
          <div className="social-media-container">
            <a href="https://twitter.com/Sony" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a>
            <a href="https://www.youtube.com/channel/UCVjS9AuBloqJJjhsy3vIfug" target="_blank" rel="noreferrer">
              <YouTubeIcon />
            </a>
          </div>
        </div>}
      </div>
      {/* <div className="sony-footer">
        <a href="https://campaign.odw.sony-europe.com/dynamic/terms-conditions/index.jsp?country=fr&language=fr" target="_blank" rel="noreferrer" >
          TERMS AND CONDITIONS OF WEBSITE USE
        </a>
        <a href="https://www.sony.fr/eu/pages/privacy/fr_FR/privacy_overview.html" target="_blank" rel="noreferrer">
          PRIVACY POLICY
        </a>
      </div> */}
    </div>
  );
};

export default Footer;
