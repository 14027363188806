import { Alert, Snackbar } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './DragAndDropContainer.css';
import handIcon from '../../assets/hand-icon.svg';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import { InputContext, IInputContext } from '../../contexts/InputContext';
import { IPlayerContext, PlayerContext } from '../../contexts/PlayerContext';
import * as Tone from "tone";
import ModelContext, { ModelContextProps } from '../../contexts/ModelContext';

const DragAndDropContainer: FC = () => {
  const {currentModel} = useContext(ModelContext) as ModelContextProps;
  const [isErrorUploadingFile, setIsErrorUploadingFile] = useState<boolean>(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] = useState<boolean>(false);

  const [uploadedFilename, setUploadedFilename] = useState<string>('');
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const { addInput } = useContext(InputContext) as IInputContext;
  const { setIsPlaying } = useContext(PlayerContext) as IPlayerContext;
  const onDropFile = (file: any, fileRejection: any, event: any) => {
    setIsPlaying(false);
    Tone.Transport.pause();
    setIsLoadingUploadingFile(true);

    if (!file[0]) {
      setIsErrorUploadingFile(true);
      setIsLoadingUploadingFile(false);
      return;
    }

    setUploadedFilename(file[0].name);
/*     const newInput = new Blob(file, { type: 'audio/wav' }); */
    addInput(file[0]);
    setIsUploadSuccess(true);
    setIsLoadingUploadingFile(false);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    accept: { 'audio/wav': ['.wav', '.flac'], 'audio/raw': ['.wav', '.flac'], "audio/mp3": [".mp3"] },
    onDrop: onDropFile,
    maxFiles: 1,
    noKeyboard: true
  });

  return (
    <div className="drag-and-drop-container-border-container">
      <div className="drag-and-drop-container-main-container">
        <Snackbar open={isErrorUploadingFile} onClose={() => setIsErrorUploadingFile(false)}>
          <Alert onClose={() => setIsErrorUploadingFile(false)} severity="error">
            Error while uploading file. Please try again later.
          </Alert>
        </Snackbar>
        <Snackbar open={isUploadSuccess} onClose={() => setIsUploadSuccess(false)}>
          <Alert onClose={() => setIsUploadSuccess(false)} severity="success" >
            File {uploadedFilename} successfully uploaded!
          </Alert>
        </Snackbar>
        <div {...getRootProps({ className: 'drag-and-drop-container' })}>
          <input {...getInputProps()} />
          {isLoadingUploadingFile ? (
            <div>Uploading file ...</div>
            //<LoadingGIFWithText width="100%" height="auto" />
          ) : (
            <>
              {(!isDragActive || isDragAccept) && (
                <SimpleSVGComponent width="22%" height="22%" icon={handIcon} alt="hand-icon" />
              )}
              {isDragActive
                ? isDragAccept
                  ? 'Dragging ...'
                  : "Can't drag this kind of file"
                : <div>
                    <div className='dd-bold-text-container'>{currentModel.dragAndDropText ?? "Drag and Drop an audio file"}</div>
                    <div className='dd-underlined-text-container'>or browse file</div>
                  </div>
                }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DragAndDropContainer;
