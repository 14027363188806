import React, { useRef, useEffect, useMemo } from "react";

interface Props {
  audioBuffer: AudioBuffer;
  audioBufferLength?: number;
  audioBufferDataArray?: Uint8Array;
  width: any;
  height: any;
  color: string;
  drawAmplitudeLines?: boolean;
  isBasslineElement?: boolean;
  channel?: number;
}

function WaveForm({ audioBuffer, audioBufferLength, audioBufferDataArray, width, height, color, drawAmplitudeLines = true, isBasslineElement=false, channel=0 }: Props) {
  const canvasRef = useRef<any>(null);

  const drawBuffer = useMemo(() => () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) return;

    if (!(audioBuffer instanceof AudioBuffer))
      return;
    
    // Get the waveform data from the audio buffer
    const data = audioBuffer.getChannelData(channel);
    if (!data) return;

    const width = canvas.width;
    const height = canvas.height;

    const step = Math.max(1, Math.ceil( ((data.length / width))));
    const amp = height / 2;

    context.fillStyle = color;
    context.clearRect(0,0,width,height);

    for(let i=0; i < width; i++){
      // loops through canvas pixels horizontally
      let max = 1.0;
      let min = -1.0;
      
      for (let j=0; j<step; j++) {
        // loops through data ponits step times
        let datum = data[(i*step)+j];
        if (datum < max)
          max = datum;
        if (datum > min)
          min = datum;
      }

      context.fillRect(i, (1+max)*amp, 1, Math.max(1,(min-max)*amp));
    }

    if (drawAmplitudeLines) {
      // Draw lower line
      context.beginPath();
      context.strokeStyle = "white";
      context.lineWidth = 0.2;
      context.moveTo(0, height * 0.1);
      context.lineTo(width, height * 0.1);
      context.stroke();

      // Draw the upper line
      context.beginPath();
      context.strokeStyle = "white";
      context.lineWidth = 0.2;
      context.moveTo(0, height * 0.9);
      context.lineTo(width, height * 0.9);
      context.stroke();

      // Draw the middle line
      context.beginPath();
      context.strokeStyle = "white";
      context.lineWidth = 0.1;
      context.moveTo(0, height * 0.35);
      context.lineTo(width, height * 0.35);
      context.stroke();

      // Draw the middle line
      context.beginPath();
      context.strokeStyle = "white";
      context.lineWidth = 0.1;
      context.moveTo(0, height * 0.65);
      context.lineTo(width, height * 0.65);
      context.stroke();
    }

  }, [audioBuffer, color, drawAmplitudeLines, channel]);

  useEffect(() => {
    drawBuffer();
}, [audioBuffer, width, height, color, drawAmplitudeLines, drawBuffer]);

  return (
    <canvas
      ref={canvasRef}
      // make it a unique id so that it doesn't get reused
      width={width}
      height={height}
      style={{ borderRadius: "10px 10px 0 0" }}
    />
  );
}

export default React.memo(WaveForm);
