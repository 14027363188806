import { FC, useEffect, useState, useContext } from "react";
import "./GuidanceContainer.css";
import RotarySlider from "./RotarySlider";
import { useKeyPress } from "../../hooks/UseKeyPress";
import { OutputContext, IOutputContext } from "../../contexts/OutputContext";

export interface GuidanceContainerProps {
  height?: string;
  width?: string;
  containerName?: string;
  leftType?: string;
  rightType?: string;
  /**
   * @default false
   * Shows gauge
   * if false : dotsGauge
   * else if true: linearGauge
   */
  showGauge?: boolean;
  /**
   * @default false
   */
  showHand?: boolean;
  /**
   * @default 2
   */
  max?: number;
  /**
   * @default 0
   */
  min?: number;
  /**
   * @default 0.02
   */
  sliderAcceleration?: number;
  value: number;
  setValue: (value: number, controlIndex?: number) => void;
  controlIndex?: number;
}

const GuidanceContainer: FC<GuidanceContainerProps> = (props) => {
  //const { setGuidance } = useContext(RequestContext) as IRequestContext;
  const [value, setValue] = useState<number>(props.value);
  const { isTextInputMode } = useContext(OutputContext) as IOutputContext;

  useEffect(() => {
    props.setValue(value, props.controlIndex);
  }, [value, props]);

  // value is between 0 and 2
  // guidance is between -1 and 5
  const onChange = (value: number) => {
    setValue(value);
    //setGuidance(value * 3 - 1);
  };

  useKeyPress(
    ["ArrowUp"],
    () => {
      if (isTextInputMode) return;
      if (value < 2) {
        setValue(value + 0.1);
        //setGuidance((value + 0.1) * 3 - 1);
      }
    },
    [38]
  );

  useKeyPress(
    ["ArrowDown"],
    () => {
      if (isTextInputMode) return;
      if (value > 0) {
        setValue(value - 0.1);
        //setGuidance((value - 0.1) * 3 - 1);
      }
    },
    [40]
  );

  return (
    <div
      className="rotary-slider-container-border-container"
      style={{ height: props.height, width: props.width }}
    >
      <div className="rotary-slider-container-main-container">
        <div className="rotary-slider-container-top-part">
          {props.leftType && (
            <div className="rotary-slider-proprety-title">{props.leftType}</div>
          )}
          <RotarySlider
            value={value}
            onChange={onChange}
            onChangeEnd={onChange}
            size={1}
            showGauge={props.showGauge}
            showHand={props.showHand}
            max={props.max}
            min={props.min}
            sliderAcceleration={props.sliderAcceleration}
          />
          {props.rightType && (
            <div className="rotary-slider-proprety-title">
              {props.rightType}
            </div>
          )}
        </div>
        {props.containerName && (
          <div className="rotary-slider-container-title">
            {props.containerName}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuidanceContainer;
