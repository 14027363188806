import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import "./HorizontalSlider.css";
import { theme } from "../../constants/theme";
import { Slider } from "@mui/material";

interface HorizontalSliderProps {
  value: number;
  onChange: (value: number) => void;
  showGauge?: boolean;
  onChangeEnd: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  splitGauge?: boolean;
  nbGaugeDots?: number;
}

const HorizontalSlider: FC<HorizontalSliderProps> = ({
  value,
  onChange,
  showGauge,
  onChangeEnd,
  min,
  max,
  step,
  splitGauge,
  nbGaugeDots,
}) => {
  const [gaugeCentralDot, setGaugeCentralDot] = useState<React.JSX.Element[]>(
    []
  );
  const [leftGaugeDots, setLeftGaugeDots] = useState<React.JSX.Element[]>([]);
  const [rightGaugeDots, setRightGaugeDots] = useState<React.JSX.Element[]>([]);

  const HorizontalSliderContainerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    if (HorizontalSliderContainerRef.current) {
      const containerHeight = HorizontalSliderContainerRef.current.offsetWidth;
      setContainerHeight(containerHeight);
    }
  }, []);

  const setGaugeDots = useCallback((
    nbDots: number,
    direction: "central" | "left" | "right" | undefined,
    reset: boolean
  ) => {
    let normalizedValue = (value - (min ?? -2)) / ((max ?? 2) - (min ?? -2));
    if (splitGauge)
      normalizedValue =
        (normalizedValue - (direction === "right" ? 0.5 : 0.5)) /
        ((direction === "right" ? 1 : 0) - (direction === "right" ? 0.5 : 0.5));
    const dotsToFill = reset ? 0 : normalizedValue * nbDots;
    const viewBox = direction === "left" ? "0 0 6 6" : "0 0.3 6 6";
    const circleStrokeWidth = direction === "central" ? "1px" : "0px";

    const dots = Array(nbDots)
      .fill(null)
      .map((_, index) => (
        <svg
          key={`rotary-slider-gauge-dot-${index}`}
          style={{
            fill:
              direction === "central"
                ? theme.palette.white
                : !direction && index <= dotsToFill
                  ? theme.palette.white
                  : !direction
                    ? theme.palette.darkOpacityGrey
                    : index < nbDots - dotsToFill
                      ? theme.palette.darkOpacityGrey
                      : theme.palette.white,
            width: "0.25vw",
            height: window.innerWidth <= 1600 ? "0.175vw" : "0.2vw",
            position: "static",
            marginLeft: "0.09vw",
            marginRight: "0.09vw",
          }}
          viewBox={viewBox}
        >
          <circle
            cx="3"
            cy="3"
            r="3"
            stroke={theme.palette.darkSalmon}
            strokeWidth={circleStrokeWidth}
          />
        </svg>
      ));
    return dots;
  }, [max, min, splitGauge, value]);

  useEffect(() => {
    if (splitGauge) {
      setGaugeCentralDot(setGaugeDots(1, "central", false));
      if (value < ((max ?? 2) - (min ?? -2)) / 2) {
        setLeftGaugeDots(setGaugeDots(nbGaugeDots ?? 13, "left", false));
        setRightGaugeDots(setGaugeDots(nbGaugeDots ?? 13, "right", true));
      } else {
        setRightGaugeDots(setGaugeDots(nbGaugeDots ?? 13, "right", false));
        setLeftGaugeDots(setGaugeDots(nbGaugeDots ?? 13, "left", true));
      }
    }
  }, [value, min, max, splitGauge, nbGaugeDots, setGaugeDots]);

  return (
    <div
      className="horizontal-slider-container"
      ref={HorizontalSliderContainerRef}
    >
      {showGauge && splitGauge && (
        <div className="horizontal-slider-gauge-container">
          <div className="horizontal-slider-gauge-container-left-side">
            {leftGaugeDots}
          </div>
          <div className="horizontal-slider-gauge-container-right-side">
            {gaugeCentralDot}
          </div>
          <div className="horizontal-slider-gauge-container-right-side">
            {rightGaugeDots}
          </div>
        </div>
      )}
      {showGauge && !splitGauge && (
        <div className="horizontal-slider-gauge-container-left-side">
          {setGaugeDots(nbGaugeDots ?? 10, undefined, false)}
        </div>
      )}
      <div className="horizontal-slider-input-container">
        <Slider
          track={false}
          sx={{
            // '& input[type="range"]': {
            //   WebkitAppearance: 'slider-horizontal'
            // },
            "& .MuiSlider-rail": {
              color: "#1A191E",
              height: "10px",
              borderRadius: "10px",
              boxShadow: "inset 2px 1px 5px 5px #000000",
            },
            "& .MuiSlider-thumb": {
              color: "#151518",
              width: "15px",
              height: "30px",
              alignItems: "center",
              boxSizing: "border-box",
              border: "5px solid",
              boxShadow: "2px 2px 1px rgb(0 0 0 / 1%)",
              borderImageSlice: 1,
              borderWidth: "0.1px",
              borderRadius: "3px",
              borderTop: "1px solid dimgray",
              borderLeft: "1px solid dimgray",
              borderRight: "1px solid dimgray",
              borderBlockEnd: "20px solid black",
            },
          }}
          style={{
            width: `${containerHeight * 0.75}px`,
          }}
          orientation="horizontal"
          defaultValue={value}
          value={value}
          min={min ?? -2}
          step={step ?? 0.01}
          max={max ?? 2}
          onChange={(event: any, value: any) => {
            onChange(value);
          }}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[]
          ) => {
            if (event.type === "touchend") {
              onChangeEnd(value as number);
            }
          }}
        />
      </div>
    </div>
  );
};

export default HorizontalSlider;
