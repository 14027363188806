import React, { FC, useContext, useState } from "react";
import "./BasslinesFooter.css";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import downloadIcon from "../../assets/player/download-icon.svg";
import Pagination from "@mui/material/Pagination";
import { theme } from "../../constants/theme";
import { tooltipClasses } from "@mui/material";
import { CustomTooltip } from "./BasslineElement";
import arrowIcon from "../../assets/bassline/add-arrow-icon.svg";
import downloadAudioIcon from "../../assets/bassline/download-audio-icon.svg";
import downloadMidiIcon from "../../assets/bassline/download-midi-icon.svg";
import {
  IDownloadAudioOptions,
  IDownloadMIDIOptions,
} from "../../contexts/RequestContext";
import {
  IOutput,
  IOutputContext,
  OutputContext,
} from "../../contexts/OutputContext";
import JSZip from "jszip";
import saveAs from "file-saver";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";

interface BasslinesFooterProps {
  numberPages: number;
  currentPageSelected: number;
  onChangeCurrentPage: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void;
  paginationSwitch: boolean;
  setPaginationSwitch: (value: boolean) => void;
}

const DownloadTooltip = () => {
  const [downloadMode, setDownloadMode] = useState<"Audio" | "MIDI">("Audio");
  const downloadAudioOptions: IDownloadAudioOptions = {
    zeroPadSelection: false,
    downloadMix: false,
  };
  const downloadMIDIOptions: IDownloadMIDIOptions = {
    includeTimeOffset: false,
    pitchWheel: false,
    tempo: 120,
  };
  const { outputs } = useContext(OutputContext) as IOutputContext;
  const { currentModel } = useContext(ModelContext) as ModelContextProps;

  const fetchDownload = async () => {
    if (downloadMode === "Audio") {
      // TODO : take in account the options (zeroad, download_mix)
      const zip = new JSZip();
      const requests = outputs.map((output: IOutput) => {
        zip.file(output.title, output.blob);
        return null;
      });
      await Promise.all(requests);
      await zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "basslines.zip");
      });
    } else {
      await currentModel.fetchTranscribe(outputs, downloadMIDIOptions);
    }
  };

  interface CheckBoxProps {
    text: string;
    type?: React.HTMLInputTypeAttribute;
    property: boolean | number;
  }

  const CheckBox: FC<CheckBoxProps> = ({
    text,
    type = "checkbox",
    property,
  }: CheckBoxProps) => {
    return (
      <div className="checkbox-container">
        <input
          type={type}
          className="checkbox"
          defaultValue={120}
          onClick={() => {
            if (type === "checkbox") property = !property;
          }}
          onChange={(e: any) => {
            if (type === "number") property = e.target.value;
          }}
        />
        <div className="checkbox-text">{text}</div>
      </div>
    );
  };

  interface TitleProps {
    title: "Audio" | "MIDI";
    icon: string;
  }

  const Title: FC<TitleProps> = ({ title, icon }: TitleProps) => {
    return (
      <div className="tooltip-title-container">
        <div
          className="tooltip-title"
          style={
            title === downloadMode ? { backgroundColor: "rgb(26, 25, 30)" } : {}
          }
          onClick={() => setDownloadMode(title)}
        >
          <SimpleSVGComponent
            icon={icon}
            alt="title-icon"
            height="1.5rem"
            width="1vw"
          />
          <div className="tootltip-title-text">{title}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="download-tooltip-container">
      <div className="download-tooltip-title-container">
        <SimpleSVGComponent
          alt="download-icon"
          icon={downloadIcon}
          height="2vh"
          width="3vh"
        />
        <div className="download-tooltip-title">Download</div>
      </div>
      <div className="download-tooltip-main-container">
        <div className="download-tooltip-main">
          <Title icon={downloadAudioIcon} title="Audio" />
          <CheckBox
            text="Zero-pad selection"
            property={downloadAudioOptions.zeroPadSelection}
          />
          <CheckBox
            text="Download mix"
            property={downloadAudioOptions.downloadMix}
          />
        </div>
        <div className="download-tooltip-main">
          <Title icon={downloadMidiIcon} title="MIDI" />
          <CheckBox
            text="Include time offset"
            property={downloadMIDIOptions.includeTimeOffset}
          />
          <CheckBox
            text="Pitchwheel"
            property={downloadMIDIOptions.pitchWheel}
          />
          <CheckBox
            text="Tempo (BPM)"
            type="number"
            property={downloadMIDIOptions.tempo}
          />
        </div>
      </div>
      <div className="download-tooltip-footer">
        <div className="download-tooltip-export-container">
          <div
            className="download-tooltip-export-background"
            onClick={fetchDownload}
          >
            <div className="download-tooltip-export">Export</div>
            <SimpleSVGComponent
              icon={arrowIcon}
              alt="arrow-icon"
              height="auto"
              width="10px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const BasslinesFooter: FC<BasslinesFooterProps> = ({
  numberPages,
  currentPageSelected,
  onChangeCurrentPage,
  paginationSwitch,
  setPaginationSwitch,
}) => {
  function onChangePaginationSwitch() {
    setPaginationSwitch(!paginationSwitch);
  }

  const [showDownload, setShowDownload] = useState<boolean>(false);

  return (
    <div className="basslines-lowerside-border-container">
      <div className="basslines-lowerside-main-container">
        <div className="footer-switch-container">
          <div
            onClick={() => onChangePaginationSwitch()}
            style={{ color: paginationSwitch ? "#625f6d" : "white" }}
          >
            scroll
          </div>
          {"  |  "}
          <div
            onClick={() => onChangePaginationSwitch()}
            style={{ color: paginationSwitch ? "white" : "#625f6d" }}
          >
            pagination
          </div>
        </div>
        {paginationSwitch && (
          <div className="footer-arrows-container">
            <Pagination
              style={{
                padding: 0,
                height: "100%",
                margin: "0",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
              size="small"
              siblingCount={0}
              boundaryCount={1}
              color="secondary"
              count={numberPages}
              page={currentPageSelected}
              onChange={onChangeCurrentPage}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "white",
                  margin: "0vw",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: theme.palette.lightGrey,
                  color: "white",
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: theme.palette.lightGrey,
                  color: "white",
                },
                "& .MuiPaginationItem-root.Mui-selected:hover": {
                  backgroundColor: theme.palette.lightGrey,
                  color: "white",
                },
              }}
            />
          </div>
        )}
        <CustomTooltip
          title={<DownloadTooltip />}
          placement="top"
          open={showDownload}
          arrow
          width={1000}
          slotProps={{
            popper: {
              sx: {
                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: "1vh",
                    borderRadius: "0.4vw",
                  },
              },
            },
          }}
        >
          <div className="bassline-footer-download-all-button-container">
            <div
              className="bassline-footer-download-all-button-title"
              onClick={() => setShowDownload(!showDownload)}
            >
              Download all
            </div>
            <div className="bassline-footer-download-all-button-icon">
              <SimpleSVGComponent
                icon={downloadIcon}
                alt="add-arrow-icon"
                cursor={true}
                height="0.8vw"
                width="0.8vw"
              />
            </div>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default BasslinesFooter;
