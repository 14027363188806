import { FC } from "react";
import "./HorizontalSliderContainer.css";
import HorizontalSlider from "./HorizontalSlider";

interface HorizontalSliderContainerProps {
  onChange: (value: number) => void;
  value: number;
  showGauge?: boolean;
  min?: number;
  max?: number;
  step?: number;
  splitGauge?: boolean;
  nbGaugeDots?: number;
}

const HorizontalSliderContainer: FC<HorizontalSliderContainerProps> = ({
  onChange,
  value,
  showGauge,
  min,
  step,
  max,
  splitGauge,
  nbGaugeDots,
}) => {
  const onChangeSlider = (value: number) => {
    onChange(value);
  };

  return (
    <div className="horizontal-slider-container-main-container">
      <HorizontalSlider
        nbGaugeDots={nbGaugeDots}
        value={value}
        onChange={onChangeSlider}
        onChangeEnd={onChangeSlider}
        showGauge={showGauge}
        max={max}
        min={min}
        step={step}
        splitGauge={splitGauge}
      />
    </div>
  );
};

export default HorizontalSliderContainer;
