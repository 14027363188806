import React, { useCallback } from 'react';
import './Timeline.css';

interface TimelineProps {
    duration: number;
    zoom?: number;
}

const Timeline: React.FC<TimelineProps> = ({ duration=0, zoom=1 }) => {
    const computeTime = useCallback(
        (secs: any) => {
          const minutes = Math.floor(secs / 60);
          const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`;
          const seconds = Math.floor(secs % 60);
          const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${returnedMinutes}:${returnedSeconds}`;
        },
        []
      );

    return (
        <>
            <div>0:00</div>
            <div className='sep-tool-progress-inside-timeline'>
                <div>{computeTime(duration / 12)}</div>
                <div>{computeTime(duration / 6)}</div>
                <div>{computeTime(duration / 4)}</div>
                <div>{computeTime(duration / 3)}</div>
                <div>{computeTime(duration * 5 / 12)}</div>
                <div>{computeTime(duration / 2)}</div>
                <div>{computeTime(duration * 7 / 12)}</div>
                <div>{computeTime(duration * 8/ 12)}</div>
                <div>{computeTime(duration * 9 / 12)}</div>
                <div>{computeTime(duration * 10 / 12)}</div>
                <div>{computeTime(duration * 11 / 12)}</div>
            </div>
            <div>{computeTime(duration)}</div>
        </>
    );
};

export default Timeline;