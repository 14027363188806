import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./WaveformContainer.css";
import WaveForm from "./WaveForm";
import { theme } from "../../constants/theme";
import Slider from "@mui/material/Slider";
import * as Tone from "tone";
import {
  IInput,
  IInputContext,
  InputContext,
} from "../../contexts/InputContext";

interface WaveformContainerProps {
  audioBuffer: AudioBuffer;
  drawAmplitudeLines?: boolean;
  inpaintingMode?: boolean;
  color: string;
  isUsingTone?: boolean;
  currentTime?: number;
  onChangeSliderValue: (value: number) => void;
  zoom?: number;
  isBasslineElement?: boolean;
  overflow?: boolean;
  item?: IInput;
  selection?: boolean;
  /**
   * Number max of seconds for the selection
   */
  max?: number;
  backgroundColor?: string;
}

const WaveformContainer: FC<WaveformContainerProps> = ({
  audioBuffer,
  drawAmplitudeLines = true,
  inpaintingMode = false,
  currentTime,
  color,
  isUsingTone = false,
  onChangeSliderValue,
  zoom = 1,
  isBasslineElement = false,
  overflow,
  item,
  selection,
  max,
  backgroundColor,
}) => {
  const ProgressCursorBarRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number>(0);
  const [audioDuration, setAudioDuration] = useState<number>(0);
  const {
    modifyInputSelection,
    getUnadded,
    setInputSelection,
    inputSelection,
  } = useContext(InputContext) as IInputContext;
  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [parentHeight, setParentHeight] = useState<number>(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectionStart, setSelectionStart] = useState<undefined | number>(
    undefined
  );
  const [isMouseDownThumb, setisMouseDownThumb] = useState(false);
  const [lastTrackPosition, setlastTrackPosition] = useState<
    number | undefined
  >(undefined);
  const [selectionMode, setSelectionMode] = useState(false);
  const [isMouseDownTrack, setIsMouseDownTrack] = useState(false);
  const [currentThumb, setCurrentThumb] = useState<number | undefined>(
    undefined
  );
  const [isOriginal, setIsOriginal] = useState(false);

  const maxRange = useMemo(() => {
    if (max) return Math.min(max / audioBuffer.duration, 1) * 100;
  }, [max, audioBuffer]);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
      setParentHeight(parentRef.current.offsetHeight);
    }
  }, []);

  const onChangeSlider = (value: number) => {
    setValue(value);
    onChangeSliderValue(value);
  };

  // range for inpainting
  const [range, setRange] = React.useState<number[]>(
    item?.selection
      ? [
          (item?.selection[0] * 100) / audioBuffer.duration,
          (item?.selection[1] * 100) / audioBuffer.duration,
        ]
      : audioBuffer
        ? [0, ((max ?? 10) * 100) / audioBuffer.duration]
        : [0, 10]
  );

  useEffect(() => {
    if (selection) {
      if (inputSelection && !isMouseDown && !isOriginal) {
        setSelectionMode(true);
        setRange(inputSelection);
      }
      if (!inputSelection) setSelectionMode(false);
    }
  }, [inputSelection]);

  useEffect(() => {
    if (selection && !selectionMode) {
      setInputSelection(undefined);
      setIsOriginal(false);
    }
  }, [selectionMode]);

  useEffect(() => {
    if (audioBuffer && item && inpaintingMode) {
      modifyInputSelection(item, [
        (range[0] / 100) * audioBuffer.duration,
        (range[1] / 100) * audioBuffer.duration,
      ]);
    }
    if (selection && audioBuffer && !inpaintingMode && isMouseDown && selectionMode) {
      setIsOriginal(true);
      setInputSelection(range);
    }
  }, [range, audioBuffer, item]);

  useEffect(() => {
    if (selectionStart && selection) setRange([selectionStart, selectionStart]);
  }, [selectionStart, selection]);

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!isMouseDownThumb && currentThumb !== undefined) {
      setCurrentThumb(undefined);
      return;
    }
    const thumb =
      document.getElementsByClassName("MuiSlider-thumb")[activeThumb + 1];
    if (thumb && isMouseDownThumb && currentThumb === undefined) {
      const thumbRect = thumb.getBoundingClientRect();
      const clientX = (event as MouseEvent).clientX;
      const clientY = (event as MouseEvent).clientY;
      if (
        clientX >= thumbRect.left - 25 &&
        clientX <= thumbRect.right + 25 &&
        clientY >= thumbRect.top - 25 &&
        clientY <= thumbRect.bottom + 25
      ) {
        setCurrentThumb(activeThumb);
        return;
      }
    }
    if (thumb && isMouseDownThumb && currentThumb !== undefined) {
      setRange(
        currentThumb === 0
          ? [
              (newValue as number[])[0],
              maxRange
                ? Math.min(range[1], maxRange + (newValue as number[])[0])
                : range[1],
            ]
          : [
              maxRange
                ? Math.max(range[0], (newValue as number[])[1] - maxRange)
                : range[1],
              (newValue as number[])[1],
            ]
      );
      return;
    }
  };

  // keep in mind that value is a percentage not a duration
  useEffect(() => {
    if (ProgressCursorBarRef.current) {
      ProgressCursorBarRef.current.style.backgroundSize = `${value}% 100%`;
    }
  }, [value, inpaintingMode]);

  useEffect(() => {
    if (audioBuffer) {
      setAudioDuration(audioBuffer.duration);
    }
  }, [audioBuffer]);

  useEffect(() => {
    if (currentTime) {
      setValue((currentTime / audioBuffer.duration) * 100);
    } else {
      setValue(0);
    }
  }, [currentTime, audioBuffer?.duration]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (audioBuffer === undefined) return;
    const duration = audioDuration;
    intervalId = setInterval(() => {
      if (ProgressCursorBarRef.current && !inpaintingMode && isUsingTone) {
        const currentTime = (Tone.Transport.seconds / duration) * 100;
        if (currentTime > 99) {
          setValue(100);
        } else setValue(currentTime);
      }
    }, 16);
    return () => {
      clearInterval(intervalId);
    };
  }, [audioDuration, audioBuffer, inpaintingMode, isUsingTone]);

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isMouseDown && selectionMode && selection) {
      let rect = e.currentTarget.getBoundingClientRect();
      const posX = ((e.clientX - rect.left) / rect.width) * 100;
      setRange([range[0], posX]);
    }
  };

  const handleDragTrack = (e: any) => {
    setIsMouseDownTrack((isMouseDown) => {
      if (isMouseDown && e.currentTarget) {
        let rect = e.currentTarget.getBoundingClientRect();
        const posX = ((e.clientX - rect.left) / rect.width) * 100;
        setlastTrackPosition((lastPos) => {
          if (lastPos && lastPos !== posX) {
            let diff = (posX - lastPos) / 100;
            setRange((range) => {
              return [
                range[0] + diff * (range[1] - range[0]),
                range[1] + diff * (range[1] - range[0]),
              ];
            });
          }
          if (lastPos === undefined) return posX;
          return lastPos;
        });
      }
      return isMouseDown;
    });
  };

  const handleMouseDownTrack = (e: any) => {
    setIsMouseDownTrack(true);
  };

  const handleMouseUpTrack = (e: any) => {
    setisMouseDownThumb(false);
    setIsMouseDownTrack((isMouseDown) => {
      if (isMouseDown) {
        setlastTrackPosition(undefined);
        return false;
      }
      return isMouseDown;
    });
  };

  const handleMouseDownThumb = () => {
    setisMouseDownThumb(true);
  };

  useEffect(() => {
    const track = document.getElementsByClassName("MuiSlider-track");
    document.addEventListener("mouseup", handleMouseUpTrack);
    document.addEventListener("mousedown", handleMouseDownThumb);

    if (track[1]) {
      track[1].addEventListener("mousedown", handleMouseDownTrack);
      track[1].addEventListener("mousemove", handleDragTrack);
    }

    return () => {
      document.removeEventListener("mouseup", handleMouseUpTrack);
      document.removeEventListener("mousedown", handleMouseDownThumb);
      if (track[1]) {
        track[1].removeEventListener("mousemove", handleDragTrack);
        track[1].removeEventListener("mousedown", handleMouseDownTrack);
      }
    };
  }, []);

  return (
    <div
      className="waveform-container"
      style={{ overflow: overflow ? "auto" : "" }}
    >
      <div
        className="overlayed-waveform-container"
        style={{
          width: `${100 * zoom}%`,
          backgroundColor: backgroundColor,
          borderRadius: "10px",
        }}
        ref={parentRef}
      >
        <WaveForm
          audioBuffer={audioBuffer}
          width={parentWidth * zoom}
          height={parentHeight}
          color={color}
          drawAmplitudeLines={drawAmplitudeLines}
          isBasslineElement={isBasslineElement}
        />
      </div>
      <div
        className="waveform-progress-cursor-bar-container"
        style={{ width: `${100 * zoom}%` }}
      >
        {!inpaintingMode && !selectionMode ? (
          <input
            onMouseDown={(e) => {
              if (selection) setIsMouseDown(true);
            }}
            ref={ProgressCursorBarRef}
            type="range"
            value={value}
            className="progress-cursor-input-bar"
            onChange={(e) => {
              onChangeSlider(Number(e.target.value));
              if (selection) {
                if (!selectionStart && isMouseDown)
                  setSelectionStart(Number(e.target.value));
                if (
                  selectionStart &&
                  Number(e.target.value) !== selectionStart
                ) {
                  console.log("H>ERE");
                  setSelectionMode(true);
                }
              }
            }}
            onMouseUp={() => {
              if (selection) {
                setIsMouseDown(false);
                setSelectionStart(undefined);
              }
            }}
            onMouseMove={(e) => {
              if (isMouseDown && selection) setSelectionMode(true);
            }}
            style={{ backgroundImage: `linear-gradient(${color}, ${color})` }}
            step="any"
          />
        ) : (
          <Slider
            onMouseDown={(e) => {
              if (selection) {
                let rect = e.currentTarget.getBoundingClientRect();
                const posX = ((e.clientX - rect.left) / rect.width) * 100;
                setIsMouseDown(true);
                onChangeSlider(posX);
                setSelectionStart(posX);
                setSelectionMode(false);
              }
            }}
            onMouseUp={() => {
              if (selection) {
                setIsMouseDown(false);
                setSelectionStart(undefined);
              }
            }}
            value={range}
            onChange={handleChange}
            onMouseMove={handleMouseMove}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
            step={0.01}
            sx={{
              width: "100%",
              height: "100%",
              zIndex: 1,
              color: theme.palette.white, // thunm bar color
              "& .MuiSlider-rail": {
                height: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "transparent",
              },
              "& .MuiSlider-track": {
                height: "100%",
                borderRadius: "0.5rem",
                backgroundColor: color,
                opacity: 0.5,
              },
              "& .MuiSlider-thumb": {
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: theme.palette.white,
                opacity: 0.5,
                border: "0.1rem solid",
                borderColor: "transparent",
                // color: theme.palette.pink,
                "&:hover": {
                  boxShadow: "0 0 0 0.05rem",
                  baxShadowColor: "transparent",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //backgroundColor: theme.palette.pink,
                },
                "&:focus": {
                  boxShadow: "0 0 0 0.25rem currentColor",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //color: theme.palette.pink,
                },
                "&.Mui-active": {
                  boxShadow: "0 0 0 0.25rem currentColor",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //color: theme.palette.pink,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default WaveformContainer;
