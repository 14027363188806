import React, { useEffect, useMemo, useState } from "react";
import "./InpaintModal.css";
import WaveformContainer from "../Waveform/WaveformContainer";
import { IInput } from "../../contexts/InputContext";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { theme } from "../../constants/theme";
import Timeline from "../Timeline/Timeline";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import TimelineDraw from "../../assets/track/timeline.svg";

export interface InpaintModalProps {
  item: IInput;
  onClose: () => void;
  onGenerate: () => void;
  invertedMode: boolean;
  setInvertedMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const InpaintModal: React.FC<InpaintModalProps> = (props) => {
  const [zoom, setZoom] = useState(1);
  const minZoom = useMemo(() => 1, []);
  const maxZoom = useMemo(() => 13, []);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
    }
  }, [parentRef.current?.offsetWidth, parentWidth, setParentWidth]);

  const setMode = (mode: "Inv" | "Inpaint") => {
    if (props.invertedMode) {
      props.setInvertedMode(false);
    } else props.setInvertedMode(true);
  };

  return (
    <div className="inpaint-modal">
      <div className="inpaint-modal-buttons-container">
        <div
          className="inpaint-modal-button"
          style={{ backgroundColor: props.invertedMode ? "" : "#3F4043" }}
          onClick={() => setMode("Inpaint")}
        >
          Inpaint
        </div>
        <div
          className="inpaint-modal-button"
          style={{ backgroundColor: props.invertedMode ? "#3F4043" : "" }}
          onClick={() => setMode("Inv")}
        >
          Inverted Inpaint
        </div>
      </div>
      <div className="inpaint-modal-main">
        <div className="inpaint-modal-main-content">
          <div className="inpaint-modal-waveform">
            <div className="inpaint-modal-timeline-container" ref={parentRef}>
              <div
                className="inpaint-progress-timeline"
                style={{ width: parentWidth }}
              >
                <SimpleSVGComponent
                  icon={TimelineDraw}
                  alt="timeline"
                  width="100%"
                  height="1vh"
                />
              </div>
              <div
                className="inpaint-modal-progress-timestamps-container"
                style={{ width: zoom === 1 ? "100%" : `${100 * zoom}%` }}
              >
                <Timeline
                  duration={props.item.audioBuffer.duration ?? 0}
                  zoom={zoom}
                />
              </div>
            </div>
            <div className="inpaint-modal-waveform-container">
              <WaveformContainer
                audioBuffer={props.item.audioBuffer}
                color={props.item.color}
                onChangeSliderValue={(value) => {}}
                inpaintingMode
                zoom={zoom}
                item={props.item}
                max={10}
              />
            </div>
          </div>
        </div>
        <div className="inpaint-modal-zoom-container">
          <ZoomOutOutlinedIcon
            sx={{
              color:
                Math.ceil(zoom) === minZoom ? theme.palette.darkGrey : "white",
              height: "1.1vw",
              width: "1.1vw",
              "&:hover": {
                cursor: Math.ceil(zoom) === minZoom ? "" : "pointer",
              },
            }}
            onClick={() => setZoom(zoom - 1)}
          />
          <RotateLeftIcon
            sx={{
              color:
                Math.ceil(zoom) === minZoom ? theme.palette.darkGrey : "white",
              height: "0.8vw",
              width: "0.8vw",
              marginRight: "0.1vw",
              "&:hover": {
                cursor: Math.ceil(zoom) === minZoom ? "" : "pointer",
              },
            }}
            onClick={() => setZoom(1)}
          />
          <ZoomInOutlinedIcon
            sx={{
              color:
                Math.ceil(zoom) === maxZoom ? theme.palette.darkGrey : "white",
              height: "1.1vw",
              width: "1.1vw",
              "&:hover": {
                cursor: Math.ceil(zoom) === maxZoom ? "" : "pointer",
              },
            }}
            onClick={() => setZoom(zoom + 1)}
          />
        </div>
      </div>
      <div className="inpaint-modal-buttons-container">
        <div className="inpaint-modal-button" onClick={props.onGenerate}>
          Generate
        </div>
        <div className="inpaint-modal-button" onClick={props.onClose}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default InpaintModal;
