import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import "./BasslinesContainer.css";
import BasslineElement from "./BasslineElement";
import BasslinesFooter from "./BasslinesFooter";
import { ViewportList } from "react-viewport-list";
import {
  OutputContext,
  IOutputContext,
  IOutput,
} from "../../contexts/OutputContext";
import emptyBasslinesIcon from "../../assets/bassnet-empty-basslines-list-icon.svg";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import socket from "../../services/socketService";
import { IGeneratingBassline, IRequestContext, RequestContext } from "../../contexts/RequestContext";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";

interface BasslinesContainerProps {}

const BasslinesContainer: FC<BasslinesContainerProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const postsPerPage = 5;
  const [paginationSwitch, setPaginationSwitch] = useState<boolean>(false);

  const { outputs } = useContext(OutputContext) as IOutputContext;
  const ref = useRef(null);

  const indexOfLastElement = currentPage * postsPerPage;
  const indexOfFirstElement = indexOfLastElement - postsPerPage;
  const [currentItems, setCurrentItems] =
    useState<(IGeneratingBassline | IOutput)[]>(outputs);
  const [progress, setProgress] = useState<number>(0);

  const { generatingBasslines, setGeneratingBasslines } = useContext(
    RequestContext
  ) as IRequestContext;
  const [nbTotalItems, setNbTotalItems] = useState<number>(
    outputs.length + generatingBasslines.length
  );
  const {currentModel} = useContext(ModelContext) as ModelContextProps;

  useEffect(() => {
    const items = [...outputs, ...generatingBasslines];
    setNbTotalItems(items.length);

    if (!paginationSwitch) {
      setCurrentItems(items);
    } else {
      setCurrentItems(items.slice(indexOfFirstElement, indexOfLastElement));
    }
  }, [outputs, generatingBasslines, paginationSwitch, currentPage, indexOfFirstElement, indexOfLastElement]);

  const handleProgress = useCallback((data: string) => {
    setGeneratingBasslines((prevGeneratingBasslines) => {
      if (prevGeneratingBasslines.length > 0 && prevGeneratingBasslines[0].isGenerating === false) {
        return [{isGenerating: true, queuePos: -1}, ...prevGeneratingBasslines.slice(1)];
      }
      return prevGeneratingBasslines;
    });

    const parsedData = typeof data === "string" ? JSON.parse(data) : data;
    if (parsedData.ending && parsedData.ending === true) {
      setProgress(0);
    }

    if (parsedData.progress) {
      setProgress(parsedData.progress * 100);
    }
  }, [setGeneratingBasslines]);

  useEffect(() => {
    socket.on("sampling_progress", handleProgress);
  }, [handleProgress]);

  return (
    <div className="basslines-container-border-container">
      <div className="basslines-container-main-container">
        {generatingBasslines.length === 0 && outputs.length === 0 ? (
          <div className="basslines-no-basslines-container">
            <SimpleSVGComponent
              icon={emptyBasslinesIcon}
              alt="empty-basslines-icon"
              height="3.1vw"
              width="3.1vw"
            />
            <div className="basslines-no-basslines-text">
              {currentModel.waitingText ?? "waiting for generation ..."}
            </div>
          </div>
        ) : (
          <>
            <div className="basslines-container-upperside">
              <div className="basslines-type-container" style={{backgroundColor: currentModel.lighterColor}}>{currentModel.outputsContainerName ?? "GENERATED"}</div>
            </div>
            <div className="basslines-container-content-container">
              <div className="basslines-table-body-container" ref={ref}>
                <ViewportList
                  overflowAnchor="none"
                  ref={ref}
                  items={currentItems}
                >
                  {(item: any, index: any) => (
                    <motion.div
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                      className={`item${index % 2 === 0 ? "" : " odd"}`}
                      key={index}
                    >
                      <BasslineElement
                        index={outputs.findIndex((output) => output.originalAudioBuffer === item.originalAudioBuffer)}
                        item={currentItems[index]}
                        progress={progress}
                      />
                    </motion.div>
                  )}
                </ViewportList>
              </div>
            </div>
            <BasslinesFooter
              numberPages={Math.ceil(nbTotalItems / postsPerPage)}
              currentPageSelected={currentPage}
              onChangeCurrentPage={(
                event: React.ChangeEvent<unknown>,
                value: number
              ) => {
                setCurrentPage(value);
              }}
              paginationSwitch={paginationSwitch}
              setPaginationSwitch={setPaginationSwitch}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BasslinesContainer;
