import io from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_API_ENDPOINT}/feed`);

socket.on('connect', ( ) => {
  console.log('Connected to server!');
});

socket.on('message', (data) => {
  console.log(data);
});

socket.on('queue_size', (data) => {
  console.log("received queue_size:", data);
});

socket.on('sampling_progress', (data) => {
  console.log("received sampling_progress:", data);
})

socket.on("my_response", (data) => {
  console.log("received my_response:", data);
});

export default socket;