import React, { ReactNode, useMemo } from "react";
import { IModel } from "../models/IModel";
import BassNetModel from "../models/BassNet";
import PianoNetModel from "../models/PianoNet";
import BassNetModel2 from "../models/BassNet2";

export interface ModelContextProps {
    currentModel: IModel;
    setCurrentModel: React.Dispatch<React.SetStateAction<IModel>>;
    models: IModel[];
}

const ModelContext = React.createContext<undefined | ModelContextProps>(undefined);

interface ModelContextProviderProps {
    children: ReactNode;
}

export const ModelContextProvider: React.FC<ModelContextProviderProps> = ({children}) => {
    const models = useMemo(() => {return [
        new BassNetModel(),
        new BassNetModel2(),
    ]}, []);
    const [currentModel, setCurrentModel] = React.useState<IModel>(models[0]);

    const contextValue = {
        currentModel,
        setCurrentModel,
        models
    }

    return (
        <ModelContext.Provider value={contextValue} >
            {children}
        </ModelContext.Provider>
    )
}

export default ModelContext;