import GuidanceContainer, { GuidanceContainerProps } from "../components/GuidanceContainer/GuidanceContainer";
import TransformContainer, { TransformContainerProps } from "../components/Transform/TransformContainer";
import { IOutput } from "../contexts/OutputContext";
import { IDownloadMIDIOptions } from "../contexts/RequestContext";

export interface IModel {
  /**
   * list containing the control objects
   */
  controls: IControl[];
  name: string;
  /**
   * Filename of the model logo, must be in src/assets/
   */
  icon: string;
  /**
   * Filename of the model name logo, must be in src/assets/
   */
  nameLogo: string;
  description: string;
  color: string;
  lighterColor: string;
  fetchGenerate: (buffer: AudioBuffer, inpaint?: AudioBuffer, clamp?: number[], invertedInpaint?: boolean, originalBufferEnd?: number, inputOffset?: number) => Promise<any>;
  fetchTranscribe: (outputs: IOutput[], info?: IDownloadMIDIOptions) => Promise<void>;
  /**
   * function that returns the control values in a list
   * @param data Object that contains the control values for the generated output, returned from fetchGenerate
   *
   * @returns a list containing the control values
   */
  getOutputControls: (data: any) => any[];
  generatedContainerName?: string;
  generateButtonText?: string;
  dragAndDropText?: string;
  waitingText?: string;
  outputsContainerName?: string;
  helper?: JSX.Element;
  generatedAudioTitle?: string;
  /**
   * @description If true, allows download on the model
   */
  godMode?: boolean;
}

export interface IControl {
  type: "HorizontalSlider" | "VerticalSlider" | "RotarySlider";
  /**
   * When defining props for a control, don't fortget to specify the type in order not to have type errors
   *
   * @example
   * props: {
   *  value: value,
   *  etc..
   * } as DropdownProps;
   */
  props: TransformContainerProps | GuidanceContainerProps;
  /**
   * Name of the control value, used to display the generated audio control informations
   *
   * @example
   * guidance
   */
  name: string;
}

// Define the mapping of control types to React components
export const ControlComponentMap: Record<IControl["type"], React.ComponentType<any>> = {
  HorizontalSlider: TransformContainer,
  RotarySlider: GuidanceContainer,
  VerticalSlider: TransformContainer,
};
