// init Player component with props

import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import playIcon from "../../assets/player/play-icon.svg";
import pauseIcon from "../../assets/player/pause-icon.svg";
import forwardIcon from "../../assets/player/forward-icon.svg";
import backwardIcon from "../../assets/player/backward-icon.svg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { VolumeMute } from "@mui/icons-material";
import { theme } from "../../constants/theme";
import "./Player.css";
import {
  IInputContext,
  InputContext,
  IInput,
} from "../../contexts/InputContext";
import { OutputContext, IOutputContext } from "../../contexts/OutputContext";
import * as Tone from "tone";
import { PlayerContext, IPlayerContext } from "../../contexts/PlayerContext";
import { useKeyPress } from "../../hooks/UseKeyPress";

interface PlayerProps {}

const Player: FC<PlayerProps> = () => {
  const { inputs, rescaleBuffer } = useContext(InputContext) as IInputContext;
  const { isTextInputMode } = useContext(OutputContext) as IOutputContext;
  const {
    currentTime,
    setCurrentTime,
    mutePlayer,
    isPlaying,
    setIsPlaying,
    players,
    setPlayers,
    setSoloForPlayer,
    setPanners,
    globalMute,
    setGlobalMute,
    setPlayersMuteState,
  } = useContext(PlayerContext) as IPlayerContext;

  // Audio Time
  const timeProgressBar = useRef<HTMLInputElement>(null);

  // Audio Playback
  const [duration, setDuration] = useState<number>(0);

  // Audio Volume
  const [volume, setVolume] = useState<number>(-6);
  const volumeBar = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (players.length === 0) {
      setDuration(0);
      setCurrentTime(0);
      setPlayersMuteState([]);
      setSoloForPlayer([]);
      if (timeProgressBar.current) {
        timeProgressBar.current.value = "0";
      }
    }
  }, [
    players,
    setCurrentTime,
    setDuration,
    setPlayersMuteState,
    setSoloForPlayer,
  ]);

  const getMaxBufferInfos = useCallback((newInputs: IInput[]) => {
    let newMaxDuration = 0;
    let newMaxSampleRate = 0;

    newInputs.forEach((input) => {
      if (input.audioBuffer.duration > newMaxDuration)
        newMaxDuration = input.duration;
      if (input.audioBuffer.sampleRate > newMaxSampleRate)
        newMaxSampleRate = input.audioBuffer.sampleRate;
    });
    return { newMaxDuration, newMaxSampleRate };
  }, []);

  // Set players filled with one player per input.audioBuffer
  const initPlayers = useCallback(() => {
    // clean up previous players
    Tone.start();
    const newSolos = inputs.map((input) => {
      const solo = new Tone.Solo();
      solo.solo = input.isSoloed;
      return solo;
    });
    const newPanners: Tone.Panner[] = [];
    const { newMaxDuration, newMaxSampleRate } = getMaxBufferInfos(inputs);
    const newPlayers = inputs.map((input, index) => {
      newPanners.push(new Tone.Panner(0).toDestination());
      const player = new Tone.Player()
        .sync()
        .connect(newPanners[index])
        .start(0);
      // check for rescaling buffer
      if (
        input.audioBuffer.duration !== newMaxDuration ||
        input.audioBuffer.sampleRate !== newMaxSampleRate
      ) {
        const rescaledInput = rescaleBuffer(
          input,
          newMaxDuration,
          newMaxSampleRate,
          input.offset ? (input.offset * newMaxDuration) / 100 : 0
        );
        player.buffer.set(rescaledInput.audioBuffer);
        return player;
      }
      player.buffer.set(input.audioBuffer);
      return player;
    });
    return { newPlayers, newPanners, newSolos };
  }, [inputs, rescaleBuffer, getMaxBufferInfos]);

  useEffect(() => {
    if (inputs.length > 0) {
      const { newPlayers, newPanners, newSolos } = initPlayers();
      setPanners(newPanners);
      setSoloForPlayer(newSolos);
      // check if there's previous state of playersMutestate
      inputs.forEach((input, index) => {
        newPlayers[index].mute = input.isMuted;
        setPlayersMuteState((prev) => {
          const newMuteState = [...prev];
          newMuteState[index] = input.isMuted;
          return newMuteState;
        });
      });
      setPlayers((prevPlayers) => {
        prevPlayers.forEach((player) => {
          player.disconnect();
          player.dispose();
        });
        return newPlayers;
      });
      setDuration(Math.max(...inputs.map((list) => list.audioBuffer.duration)));
    }
  }, [
    inputs,
    initPlayers,
    setPanners,
    setSoloForPlayer,
    setPlayers,
    setPlayersMuteState,
  ]);

  const togglePlayPause = useCallback(() => {
    if (players.length === 0 || !timeProgressBar.current) return;
    // console log from transport all the players that are connected to it
    if (isPlaying) {
      if (Tone.context.state !== "running") {
        Tone.start();
      }
      Tone.Transport.start();
    } else {
      Tone.Transport.pause();
    }
  }, [isPlaying, players]);

  useEffect(() => {
    togglePlayPause();
  }, [isPlaying, togglePlayPause]);

  useKeyPress(
    [" "],
    () => {
      if (isTextInputMode) return;
      setIsPlaying(!isPlaying);
    },
    [32]
  );

  // Update timeProgressBar value and currentTime
  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (isPlaying) {
      intervalId = setInterval(() => {
        if (players.length > 0 && isPlaying && timeProgressBar.current) {
          const currentTime = Tone.Transport.seconds;
          if (currentTime < duration) {
            timeProgressBar.current.value = String(currentTime);
            setCurrentTime(currentTime);
          } else {
            setIsPlaying(false);
            timeProgressBar.current.value = String(0);
            setCurrentTime(0);
            Tone.Transport.stop();
          }
        }
      }, 16);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, duration, players, setIsPlaying, setCurrentTime]);

  const changeVolume = () => {
    if (players.length > 0 && volumeBar.current) {
      players.forEach((player) => {
        if (player.mute) return;
        player.volume.value = Number(volumeBar.current?.value);
      });
      setVolume(Number(volumeBar.current.value));
    }
  };

  useKeyPress(
    ["m"],
    () => {
      if (isTextInputMode) return;
      setGlobalMute((state) => {
        players.forEach((player, index) => {
          mutePlayer(index, !state);
          inputs[index].isMuted = !state;
        });
        return !state;
      });
    },
    [77]
  );

  const computeTime = useCallback((secs: any) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }, []);

  const backwardAudio = () => {
    if (players.length === 0 || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) - inputs[0].audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const changeTimePosition = () => {
    if (players.length === 0 || !timeProgressBar.current) return;
    const currentTimeValue = Number(timeProgressBar.current.value);
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(currentTimeValue / duration) * 100}%`
    );
    setCurrentTime(currentTimeValue);
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const forwardAudio = () => {
    if (players.length === 0 || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) + inputs[0].audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  return (
    <>
      <div className="player-border-container">
        <div className="player-main-container">
          <div className="audio-player-controls-container">
            {!isMobile && (
              <div className="audio-fd-cd-icon" onClick={backwardAudio}>
                <SimpleSVGComponent
                  icon={backwardIcon}
                  alt="backward-icon"
                  cursor={true}
                />
              </div>
            )}
            <div
              className="audio-player-icon"
              onClick={() => setIsPlaying(!isPlaying)}
              style={{
                backgroundColor: theme.palette.lightGrey,
                borderRadius: "50%",
                boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              {isPlaying ? (
                <SimpleSVGComponent
                  icon={pauseIcon}
                  alt="pause-icon"
                  cursor={true}
                  height="0.8vw"
                  width="0.8vw"
                />
              ) : (
                <SimpleSVGComponent
                  icon={playIcon}
                  alt="play-icon"
                  cursor={true}
                  height="0.8vw"
                  width="0.8vw"
                />
              )}
            </div>
            {!isMobile && (
              <div className="audio-fd-cd-icon" onClick={forwardAudio}>
                <SimpleSVGComponent
                  icon={forwardIcon}
                  alt="forward-icon"
                  cursor={true}
                />
              </div>
            )}
          </div>
          <div className="audio-player-time-container">
            <div className="audio-player-time-indicator-container">
              <div>{computeTime(currentTime)}</div>
            </div>
            <div className="audio-progress-bar-container">
              <input
                type="range"
                className="audio-progress-bar"
                ref={timeProgressBar}
                defaultValue="0"
                onChange={changeTimePosition}
                min={0}
                max={duration}
                step={0.01}
              />
            </div>
            <div className="audio-player-time-indicator-container">
              {!isNaN(duration) && computeTime(duration)}
            </div>
          </div>
          <div className="player-elements-separator">|</div>
          <div className="volume-container">
            <div
              className="volume-button-container"
              onClick={() =>
                setGlobalMute((state) => {
                  players.forEach((player, index) => {
                    mutePlayer(index, !state);
                    inputs[index].isMuted = !state;
                  });
                  return !state;
                })
              }
            >
              {globalMute ? (
                <VolumeMute style={{ height: "1rem", width: "1rem" }} />
              ) : (
                <VolumeUpIcon style={{ height: "1rem", width: "1rem" }} />
              )}
            </div>
            <div className="volume-input-container">
              <input
                type="range"
                className="audio-progress-bar"
                ref={volumeBar}
                onChange={changeVolume}
                value={volume}
                max={0}
                min={-100}
                step={1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;
