import { FC, useContext } from 'react';
import './TracksListHeader.css';
import InfoOutlinedIcon from '../../assets/bassnet-inputs-icon.svg';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import unfold from '../../assets/bassline/unfold-icon.svg';
import fold from '../../assets/bassline/fold-icon.svg';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { theme } from '../../constants/theme';
import ModelContext, { ModelContextProps } from '../../contexts/ModelContext';

interface TracksListHeaderProps {
    title?: string;
    icon?: any;
    isFolded?: boolean;
    setIsFolded?: (isFolded: boolean) => void;
    zoom?: number;
    maxZoom?: number;
    minZoom?: number;
    zoomIn?: () => void;
    zoomOut?: () => void;
    setZoom?: (zoom: number) => void;
}

const TracksListHeader: FC<TracksListHeaderProps> = (
    {
        title = "Audio Inputs",
        icon = InfoOutlinedIcon,
        isFolded = false,
        setIsFolded = () => {},
        maxZoom = 2,
        minZoom = 1,
        zoom = 1,
        zoomIn = () => {},
        zoomOut = () => {},
        setZoom = () => {},
    }
) => {
    const {currentModel} = useContext(ModelContext) as ModelContextProps;

    const toggleFold = () => {
        const value = isFolded;
        setIsFolded(!value);
    };

    return (
        <div className='audio-inputs-header-border-container'>
            <div className='audio-inputs-header-main-container'>
                <div className='audio-inputs-header-title-container' style={{backgroundColor: currentModel.lighterColor}}>
                    <SimpleSVGComponent icon={icon} alt='audio-inputs-header-icon' height='1.1vw' width='1.1vw' />
                    {title}
                </div>
                <div className='audio-inputs-header-conatiner-props-side'>
                    <div className='audio-inputs-header-zoom-buttons-container'>
                        <ZoomOutOutlinedIcon sx={{ color: (Math.ceil(zoom) === minZoom) ? theme.palette.darkGrey : 'white', height: '1.1vw', width: '1.1vw', '&:hover': { cursor: (Math.ceil(zoom) === minZoom) ? "" : 'pointer' } }} onClick={zoomOut} />
                        <RotateLeftIcon sx={{ color: (Math.ceil(zoom) === minZoom) ? theme.palette.darkGrey : 'white', height: '0.8vw', width: '0.8vw', marginRight: '0.1vw', '&:hover': { cursor: (Math.ceil(zoom) === minZoom) ? "" : 'pointer' } }} onClick={() => setZoom(1)} />
                        <ZoomInOutlinedIcon sx={{ color: (Math.ceil(zoom) === maxZoom) ? theme.palette.darkGrey : 'white', height: '1.1vw', width: '1.1vw', '&:hover': { cursor: (Math.ceil(zoom) === maxZoom) ? "" : 'pointer' } }} onClick={zoomIn} />
                    </div>
                        {isFolded ? (
                        <div className='audio-inputs-header-fold-all-button-container' onClick={toggleFold}>
                            {"Close all"}
                            <SimpleSVGComponent icon={unfold} alt='fold-all' height='0.52vw' width='0.52vw' />
                        </div>
                        ) : (
                        <div className='audio-inputs-header-fold-all-button-container' onClick={toggleFold}>
                            {"Open all"}
                            <SimpleSVGComponent icon={fold} alt='unfold-all' height='0.52vw' width='0.52vw' />
                        </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default TracksListHeader;
