import { FC, useContext, useEffect, useRef, useState } from "react";
import "./AudioTrack.css";
import titleEditIcon from "../../assets/track/title-edit-icon.svg";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import { Knob } from "react-rotary-knob";
import { s9 } from "react-rotary-knob-skin-pack";
import { theme } from "../../constants/theme";
import downloadIcon from "../../assets/player/download-icon.svg";
import inpaintingIcon from "../../assets/track/inpainting-icon.svg";
import unfold from "../../assets/bassline/unfold-icon.svg";
import fold from "../../assets/bassline/fold-icon.svg";
import addToInputIcon from "../../assets/track/add-to-input-icon.svg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { VolumeMute } from "@mui/icons-material";
import { OutputContext, IOutputContext } from "../../contexts/OutputContext";
import {
  IInput,
  IInputContext,
  InputContext,
} from "../../contexts/InputContext";
import WaveformContainer from "../Waveform/WaveformContainer";
import removeIcon from "../../assets/bassline/remove-icon.svg";
import removeFromInputIcon from "../../assets/track/remove-from-input-icon.svg";
import { computeTime } from "../../tools/computeTime";
import emptyInputsIcon from "../../assets/bassnet-empty-inputs-list-icon.svg";
import emptyOutputsIcon from "../../assets/bassnet-empty-other-list-icon.svg";
import * as Tone from "tone";
import { PlayerContext, IPlayerContext } from "../../contexts/PlayerContext";
import { useKeyPress } from "../../hooks/UseKeyPress";
import { IRequestContext, RequestContext } from "../../contexts/RequestContext";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";

interface AudioTrackProps {
  inputItem: IInput;
  isGeneratedInput?: boolean;
  index: number;
  isSectionFolded?: boolean;
  zoom?: number;
  isMutedTrack?: boolean;
  isSoloedTrack?: boolean;
}

const AudioTrack: FC<AudioTrackProps> = ({
  inputItem,
  isGeneratedInput = false,
  index,
  isSectionFolded = false,
  zoom = 1,
  isMutedTrack = false,
  isSoloedTrack = false,
}) => {
  const {
    convertAudioBufferToBlobAndDownload,
    isTextInputMode,
    setIsTextInputMode,
  } = useContext(OutputContext) as IOutputContext;
  const {
    inputs,
    removeInput,
    removeInputFromAdded,
    addInputToAdded,
    modifyInputTitle,
    selectedInput,
    setSelectedInput,
  } = useContext(InputContext) as IInputContext;
  const {
    setIsPlaying,
    players,
    setSoloIndex,
    cleanUpPlayers,
    mutePlayer,
    soloIndex,
    unSoloPlayer,
    soloPlayer,
    changePlayerVolume,
    panners,
    globalMute,
    setPlayersMuteState,
    setSoloForPlayer,
  } = useContext(PlayerContext) as IPlayerContext;
  const [emptyMode, setEmptyMode] = useState<boolean>(false);
  const { inpaintingItem, setInpaintingItem } = useContext(
    RequestContext
  ) as IRequestContext;
  const {currentModel} = useContext(ModelContext) as ModelContextProps;

  let [PannerValue, setPannerValue] = useState(0);
  const [isFolded, setIsFolded] = useState<boolean>(false);
  const [item, setItem] = useState<IInput>(inputItem);

  // Updates Panner value when local PannerValue changes
  useEffect(() => {
    if (panners[index] !== undefined) panners[index].pan.value = PannerValue;
  }, [PannerValue, panners, index]);

  // key press enter on title input
  useKeyPress(
    ["Enter"],
    () => {
      if (isTextInputMode) setIsTextInputMode(false);
    },
    [13]
  );

  useKeyPress(
    ["Escape"],
    () => {
      if (isTextInputMode) {
        setIsTextInputMode(false);
      }
    },
    [27]
  );

  useKeyPress(
    ["S"],
    () => {
      if (selectedInput === item) {
        setIsSolo((state) => {
          if (!state) {
            soloPlayer(index);
            setSoloIndex(index);
            item.isSoloed = true;
          } else {
            unSoloPlayer(index);
            setSoloIndex(-1000);
            item.isSoloed = false;
          }
          return !state;
        });
      }
    },
    [83]
  );

  // set is text input mode to false when clicking outside of the input
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.className !== "bassnet-audio-track-title-input"
      ) {
        setIsTextInputMode(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTextInputMode, setIsTextInputMode]);

  // set selecred input to null when clicking outside of the input
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        e.target.id !== `audio-track-${index}`
      ) {
        setSelectedInput(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedInput, setSelectedInput, index]);

  // Set empty AudioTrack template
  useEffect(() => {
    if (inputs.length === 0) {
      // add title to empty input
      setItem({
        title: isGeneratedInput ? "Generated Output" : "Imported Input",
        audioBuffer: {} as AudioBuffer,
        originalAudioBuffer: {} as AudioBuffer,
        color: theme.palette.lightGrey,
        added: false,
        duration: 0,
        isMuted: false,
        isSoloed: false,
        godMode: false,
      });
      setEmptyMode(true);
    } else {
      setEmptyMode(false);
    }
  }, [inputs.length, isGeneratedInput]);

  useEffect(() => {
    setItem(inputItem);
  }, [inputItem]);

  const toggleFold = () => {
    const value = isFolded;
    setIsFolded(!value);
  };

  useEffect(() => {
    const generalValue = isSectionFolded;
    setIsFolded(generalValue);
  }, [isSectionFolded]);

  const handleDownload = () => {
    convertAudioBufferToBlobAndDownload(item.originalAudioBuffer, item.title);
  };

  // Audio Volume
  const [isMuted, setIsMuted] = useState<boolean>(isMutedTrack);
  const volumeBar = useRef<HTMLInputElement>(null);
  const [isSolo, setIsSolo] = useState<boolean>(isSoloedTrack);
  const [muteFromSolo, setMuteFromSolo] = useState<boolean>(false);

  useEffect(() => {
    if (soloIndex === -1000) {
      setMuteFromSolo(false);
      return;
    }
    if (soloIndex === index) {
      setMuteFromSolo(false);
      item.isSoloed = true;
      mutePlayer(index, false);
    } else {
      setMuteFromSolo(true);
      item.isSoloed = false;
      mutePlayer(index, true);
    }
  }, [muteFromSolo, index, soloIndex, item, mutePlayer]);

  useEffect(() => {
    setIsMuted(isMutedTrack);
    setIsSolo(isSoloedTrack);
  }, [isMutedTrack, setIsMuted, isSoloedTrack, setIsSolo]);

  // Solo Player
  useEffect(() => {
    if (globalMute) {
      setIsSolo(false);
      item.isSoloed = false;
      item.isMuted = true;
      setSoloIndex(-1000);
      return;
    }
  }, [isSolo, index, soloPlayer, unSoloPlayer, globalMute, setSoloIndex, item]);

  // Mute player from Global Player Mute
  useEffect(() => {
    if (globalMute) {
      setIsMuted(globalMute);
    } else {
      setIsMuted(item.isMuted);
    }
  }, [globalMute, item.isMuted]);

  const changeVolume = () => {
    if (players.length === 0) return;
    const muteState = players[index].mute;
    if (muteState) mutePlayer(index, muteState);
    changePlayerVolume(index, volumeBar.current!.valueAsNumber);
  };

  const handleRemove = () => {
    setIsPlaying(false);
    removeInput(index);
    cleanUpPlayers();
  };

  const handleSideButton = () => {
    const newPlayersMuteState = players.map((player) => player.mute);
    setPlayersMuteState(newPlayersMuteState);
    const newSoloForPlayer = players.map((player) => new Tone.Solo());
    setSoloForPlayer(newSoloForPlayer);
    if (isGeneratedInput) {
      // add to input
      setIsPlaying(false);
      Tone.Transport.pause();
      // reorder the playersMuteState and soloForPlayer
      removeInputFromAdded(index);
    } else {
      // remove from input
      setIsPlaying(false);
      Tone.Transport.pause();
      addInputToAdded(index);
    }
  };

  return (
    <div className="bassnet-audio-track-border-container">
      <div
        className="bassnet-audio-track-main-container"
        id={`audio-track-${index}`}
      >
        <div className="bassnet-audio-track-active-container">
          {!emptyMode ? (
            <div
              className="bassline-audio-track-side-components-container"
              onClick={handleSideButton}
            >
              {isGeneratedInput ? (
                <SimpleSVGComponent
                  icon={addToInputIcon}
                  alt="add-to-input-icon"
                  cursor={true}
                  height="1.3vw"
                  width="1.5vw"
                />
              ) : (
                <SimpleSVGComponent
                  icon={removeFromInputIcon}
                  alt="remove-from-input-icon"
                  cursor={true}
                  height="1.3vw"
                  width="1.5vw"
                />
              )}
            </div>
          ) : null}
          <div
            className="bassline-audio-track-internal-components-container"
            onClick={() => {
              if (item !== selectedInput) {
                setSelectedInput(item);
              }
            }}
            style={{
              backgroundColor:
                selectedInput === item ? theme.palette.grey : "",
            }}
          >
            <div className="bassnet-audio-track-props-container">
              <div className="bassnet-audio-track-props-base-props-container">
                {isGeneratedInput && item.godMode && (
                  <div
                    className="bassline-download-icon"
                    onClick={handleDownload}
                    style={{
                      backgroundColor: theme.palette.lightGrey,
                      borderRadius: "50%",
                      width: "1.5vw",
                      height: "1.5vw",
                      boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <SimpleSVGComponent
                      icon={downloadIcon}
                      alt="download-icon"
                      cursor={true}
                      height="0.8vw"
                      width="0.8vw"
                    />
                  </div>
                )}
                <div className="bassnet-audio-track-time-container">
                  {computeTime(
                    item.originalAudioBuffer
                      ? item.originalAudioBuffer.duration
                      : 0
                  )}
                </div>
                <div className="bassnet-audio-track-title-container">
                  {isTextInputMode ? (
                    <input
                      className="bassnet-audio-track-title-input"
                      type="text"
                      defaultValue={item.title}
                      onChange={(e) => modifyInputTitle(index, e.target.value)}
                      style={{
                        backgroundColor: theme.palette.black,
                        color: theme.palette.white,
                        border: "transparent",
                        outline: "none",
                      }}
                      maxLength={30}
                    />
                  ) : (
                    item.title
                  )}
                  {isGeneratedInput ? (
                    <SimpleSVGComponent
                      icon={titleEditIcon}
                      alt="title-edit-icon"
                      height="0.8vw"
                      width="0.8vw"
                      onClick={() =>
                        setIsTextInputMode((state) => {
                          setIsPlaying(false);
                          Tone.Transport.pause();
                          return !state;
                        })
                      }
                      cursor={true}
                    />
                  ) : null}
                </div>
                <div
                  className="bassnet-audio-track-square-button"
                  onClick={() => {
                    setIsMuted((state) => {
                      mutePlayer(index, !state);
                      item.isMuted = !state;
                      return !state;
                    });
                  }}
                  style={{
                    backgroundColor: isMuted ? theme.palette.lightGrey : "",
                    color: isMuted ? theme.palette.lowIntenseOrange : "",
                    zIndex: isMuted && soloIndex === -1000 ? 1 : 0,
                  }}
                >
                  M
                </div>
                <div
                  className="bassnet-audio-track-square-button"
                  onClick={() => {
                    setIsSolo((state) => {
                      if (!state) {
                        soloPlayer(index);
                        setSoloIndex(index);
                        item.isSoloed = true;
                      } else {
                        unSoloPlayer(index);
                        setSoloIndex(-1000);
                        item.isSoloed = false;
                      }
                      return !state;
                    });
                  }}
                  style={{
                    backgroundColor: isSolo ? theme.palette.lightGrey : "",
                    color: isSolo ? theme.palette.navyBlue : "",
                  }}
                >
                  S
                </div>
                {isGeneratedInput ? (
                  <div
                    className="bassnet-audio-track-square-button"
                    // onClick={() => setInpaintingItem(inputItem)} // INPAINT DISABLED FOR NOW
                    style={{
                      backgroundColor: inpaintingItem
                        ? theme.palette.lightGrey
                        : "",
                    }}
                  >
                    <SimpleSVGComponent
                      icon={inpaintingIcon}
                      alt="inpainting-icon"
                      height="1.05vw"
                      width="1.1vw"
                      cursor={true}
                    />
                  </div>
                ) : null}
                <div className="bassnet-audio-track-knob-container">
                  <div className="bassnet-audio-track-knob">
                    L
                    <Knob
                      style={{
                        width: "1.8vw",
                        height: "1.8vw",
                      }}
                      onChange={(value: number) => setPannerValue(value)}
                      min={-1}
                      max={1}
                      value={PannerValue}
                      defaultValue={0}
                      unlockDistance={5}
                      skin={s9}
                      preciseMode={false}
                      rotateDegrees={180}
                    />
                    R
                  </div>
                  {PannerValue.toFixed(1)}
                </div>
              </div>
              <div className="bassnet-audio-track-props-volume-fold-container">
                <div className="bassnet-audio-track-volume-container">
                  <div
                    className="bassnet-audio-track-volume-button-container"
                    onClick={() => {
                      setIsMuted((state) => {
                        mutePlayer(index, !state);
                        item.isMuted = !state;
                        return !state;
                      });
                    }}
                  >
                    {isMuted ? (
                      <VolumeMute style={{ height: "1rem", width: "1rem" }} />
                    ) : (
                      <VolumeUpIcon style={{ height: "1rem", width: "1rem" }} />
                    )}
                  </div>
                  <div className="bassnet-audio-track-volume-input-container">
                    <input
                      type="range"
                      className="audio-progress-bar"
                      ref={volumeBar}
                      min={-100}
                      max={0}
                      defaultValue={-6}
                      step={1}
                      onChange={changeVolume}
                    />
                  </div>
                </div>
                <div
                  className="bassnet-audio-track-folding-icon"
                  onClick={toggleFold}
                  style={{
                    paddingTop: isFolded ? "0.1vw" : "0.2vw",
                    paddingBottom: !isFolded ? "0.1vw" : "0.2vw",
                    paddingLeft: "0.2vw",
                    paddingRight: !isFolded ? "0.2vw" : "0.22vw",
                  }}
                >
                  {isFolded ? (
                    <SimpleSVGComponent
                      icon={unfold}
                      alt="unfold-icon"
                      cursor={true}
                      height="0.6vw"
                      width="0.6vw"
                    />
                  ) : (
                    <SimpleSVGComponent
                      icon={fold}
                      alt="fold-icon"
                      cursor={true}
                      height="0.6vw"
                      width="0.6vw"
                    />
                  )}
                </div>
                <div
                  className="bassnet-audio-track-remove-icon"
                  onClick={handleRemove}
                >
                  <SimpleSVGComponent
                    icon={removeIcon}
                    alt="remove-icon"
                    cursor={true}
                    height="0.5vw"
                    width="0.5vw"
                  />
                </div>
              </div>
            </div>
            {isFolded ? (
              <div className="bassnet-audio-track-waveform-container">
                <WaveformContainer
                  audioBuffer={item.audioBuffer}
                  /* inpaintingMode={inpaintingMode} */
                  color={item.color}
                  isUsingTone={true}
                  onChangeSliderValue={(value: number) => {
                    if (item.audioBuffer)
                      Tone.Transport.seconds =
                        (value * item.audioBuffer.duration) / 100;
                  }}
                  zoom={zoom}
                  // selection={!isGeneratedInput}
                  backgroundColor={theme.palette.black}
                  item={item}
                  drawAmplitudeLines={false}
                />
              </div>
            ) : null}
          </div>
        </div>
        {emptyMode || muteFromSolo || isMuted ? (
          <div className="bassnet-no-audio-tracks-container">
            {emptyMode ? (
              <>
                {!isGeneratedInput ? (
                  <>
                    <SimpleSVGComponent
                      icon={emptyInputsIcon}
                      alt="empty-inputs-icon"
                      cursor={false}
                      height="2.5vw"
                      width="2.5vw"
                    />
                    <div className="bassnet-no-audio-tracks-text">
                      Waiting for audio inputs ...
                    </div>
                  </>
                ) : (
                  <>
                    <SimpleSVGComponent
                      icon={emptyOutputsIcon}
                      alt="empty-outputs-icon"
                      cursor={false}
                      height="2.5vw"
                      width="2.5vw"
                    />
                    <div className="bassnet-no-audio-tracks-text">
                      Waiting for audio outputs ...
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="bassnet-no-audio-tracks-text">Muted audio</div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AudioTrack;
