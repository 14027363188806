import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import socket from "../services/socketService";
import { IOutputContext, OutputContext } from "./OutputContext";
import { IInput, IInputContext, InputContext } from "./InputContext";

export interface IRequestContext {
  isSelectionMode: boolean;
  setIsSelectionMode: React.Dispatch<React.SetStateAction<boolean>>;
  generatingBasslines: IGeneratingBassline[];
  setGeneratingBasslines: React.Dispatch<
    React.SetStateAction<IGeneratingBassline[]>
  >;
  setGeneratingBasslinesValue: (
    index: number,
    isGenerating: boolean,
    queuePos: number
  ) => void;
  nbPendingRequests: number;
  setNbPendingRequests: React.Dispatch<React.SetStateAction<number>>;
  getInputsBuffer: (newSampleRate?: number) => Promise<AudioBuffer>;
  downloadGenerated: (data: any) => Promise<void>;
  inpaintingItem: undefined | IInput;
  setInpaintingItem: React.Dispatch<React.SetStateAction<undefined | IInput>>;
  convertAudioBufferSampleRate: (
    audioBuffer: AudioBuffer,
    sampleRate?: number
  ) => Promise<AudioBuffer>;
}

export const RequestContext = createContext<null | IRequestContext>(null);

export interface IDownloadAudioOptions {
  zeroPadSelection: boolean;
  downloadMix: boolean;
}

export interface IDownloadMIDIOptions {
  includeTimeOffset: boolean;
  pitchWheel: boolean;
  tempo: number;
}

interface RequestContextProviderProps {
  children: React.ReactNode;
}

export interface IGeneratingBassline {
  isGenerating: boolean;
  queuePos: number;
}

export const RequestContextProvider = ({
  children,
}: RequestContextProviderProps) => {
  const [generatingBasslines, setGeneratingBasslines] = useState<
    IGeneratingBassline[]
  >([]);
  const [isSelectionMode, setIsSelectionMode] = useState<boolean>(false);
  const [nbPendingRequests, setNbPendingRequests] = useState(0);
  const [lastQueueNumber, setLastQueueNumber] = useState(-1);
  const [inpaintingItem, setInpaintingItem] = useState<IInput | undefined>(
    undefined
  );

  const { getUnadded, inputSelection } = useContext(
    InputContext
  ) as IInputContext;
  const { addOutput } = useContext(OutputContext) as IOutputContext;

  const handleQueue = useCallback(
    (data: any) => {
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;
      if (parsedData.queue_size) {
        setGeneratingBasslines((basslines) => {
          let temp = [...basslines];
          const index = temp.findIndex(
            (bassline) => !bassline.isGenerating && bassline.queuePos === -1
          );
          if (index !== -1) {
            temp[index].queuePos = parsedData.queue_size;
            return temp;
          } else return basslines;
        });
      }
      if (parsedData.reduction && parsedData.q) {
        setGeneratingBasslines((basslines) => {
          if (parsedData.q !== lastQueueNumber) {
            let temp = [...basslines];
            temp.forEach((bassline, index) => {
              if (parsedData.cancelled <= bassline.queuePos)
                bassline.queuePos += parsedData.reduction;
            });
            setLastQueueNumber(parsedData.q);
            return temp;
          } else return basslines;
        });
      }
    },
    [lastQueueNumber]
  );

  useEffect(() => {
    socket.on("queue_size", handleQueue);
  }, [handleQueue]);

  const setGeneratingBasslinesValue = (
    index: number,
    isGenerating: boolean,
    queuePos: number
  ) => {
    const temp = [...generatingBasslines];
    temp[index] = { isGenerating, queuePos };
    setGeneratingBasslines(temp);
  };

  /*   const getSelectionStartEnd = () => {
    const duration = Math.max(
      ...getAdded().map((list) => list.audioBuffer.duration)
    );
    let sel_start = 0;
    let sel_end = 30;

    if (selection[0] < selection[1]) {
      console.log("selection", selection);
      sel_start = selection[0];
      sel_end = selection[1];
    }
    sel_start = Math.max(sel_start, 0);
    sel_end = Math.min(duration, sel_end);
    return [sel_start, sel_end];
  }; */

  const downloadGenerated = async (data: any) => {
    try {
      const filepath = `${process.env.REACT_APP_API_ENDPOINT}/${data.url}`;
      const response = await fetch(filepath);
      const blob = await response.blob();

      // Create a File object with the blob data and filename
      const filename = data.url.substring(data.url.lastIndexOf("/") + 1); // Extract filename from URL
      const file = new File([blob], filename, { type: blob.type });
      setNbPendingRequests((prev) => prev - 1);
      setGeneratingBasslines((prev) => [...prev.slice(1)]);
      await addOutput(file, data, blob);
    } catch (error) {
      console.error("Error fetching file:", error);
      setNbPendingRequests((prev) => prev - 1);
      setGeneratingBasslines((prev) => [...prev.slice(1)]);
    }
  };

  const convertAudioBufferSampleRate = async (
    audioBuffer: AudioBuffer,
    sampleRate = 16000
  ) => {
    const nb_samples = Math.floor(audioBuffer.duration * sampleRate);
    const offlineContext = new OfflineAudioContext({
      numberOfChannels: 1,
      length: nb_samples,
      sampleRate: sampleRate,
    });

    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(offlineContext.destination);
    bufferSource.start(0, 0, audioBuffer.duration);

    const resampledBuffer = await offlineContext.startRendering();
    return resampledBuffer;
  };

  const getInputsBuffer = async (newSampleRate = 16000) => {
    const unaddedInputs = getUnadded();
    //selection not used except for inpainting
    /* const [sel_start, sel_end] = getSelectionStartEnd();
    const sel_dur = sel_end - sel_start; */
    let duration = Math.max(
      ...unaddedInputs.map((list) => list.audioBuffer.duration)
    );
    if (inputSelection) {
      duration = (Math.abs(inputSelection[1] - inputSelection[0]) * duration) / 100;
    }
    const nb_samples = Math.floor(duration * newSampleRate);

    const offlineContext = new OfflineAudioContext({
      numberOfChannels: 1,
      length: nb_samples,
      sampleRate: newSampleRate,
    });

    for (const input of unaddedInputs) {
      const bufferSource = offlineContext.createBufferSource();
      bufferSource.buffer = input.audioBuffer;
      bufferSource.connect(offlineContext.destination);
      bufferSource.start(0, inputSelection ? Math.min(...inputSelection) : 0, Math.min(duration, 30));
    }

    const resampledBuffer = await offlineContext.startRendering();
    return resampledBuffer;
  };

  const contextValue = {
    generatingBasslines,
    setGeneratingBasslines,
    setGeneratingBasslinesValue,
    isSelectionMode,
    setIsSelectionMode,
    nbPendingRequests,
    setNbPendingRequests,
    getInputsBuffer,
    downloadGenerated,
    inpaintingItem,
    convertAudioBufferSampleRate,
    setInpaintingItem,
  };

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
};
