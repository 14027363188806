import { createContext, useContext, useState } from "react";
import { saveAs } from "file-saver";
import { IInputContext, InputContext } from "./InputContext";
import ModelContext, { ModelContextProps } from "./ModelContext";
import { PlayerContext, IPlayerContext } from "./PlayerContext";

export interface IOutputContext {
  outputs: IOutput[];
  addOutput: (file: File, data: any, blob: Blob) => Promise<null>;
  setOutputs: React.Dispatch<React.SetStateAction<IOutput[]>>;
  removeOutput: (index: number) => void;
  convertAudioBufferToBlobAndDownload: (
    audioBuffer: AudioBuffer,
    filename: string
  ) => Blob;
  modifyOutputTitle: (index: number, newTitle: string) => void;
  addOutputToInput: (index: number) => void;
  getNextColor: (list: any[]) => string;
  isGenerating?: boolean;
  setIsGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  isTextInputMode: boolean;
  setIsTextInputMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IOutput {
  title: string;
  duration: number;
  controls: any[];
  color: string;
  generatedAt: string;
  audioBuffer: AudioBuffer;
  originalAudioBuffer: AudioBuffer;
  blob: Blob;
  offset: number;
  added: boolean;
  model: string;
  godMode?: boolean;
}

export const OutputContext = createContext<null | IOutputContext>(null);

interface OutputContextProviderProps {
  children: React.ReactNode;
}

export const OutputContextProvider = ({
  children,
}: OutputContextProviderProps) => {
  const { inputs, setInputs } = useContext(InputContext) as IInputContext;
  const [outputs, setOutputs] = useState<IOutput[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isTextInputMode, setIsTextInputMode] = useState<boolean>(false);
  const {currentModel} = useContext(ModelContext) as ModelContextProps;
  const { pauseAllPlayers } = useContext(PlayerContext) as IPlayerContext;

  const modifyOutputTitle = (index: number, newTitle: string) => {
    const newOutputs = [...outputs];
    newOutputs[index].title = newTitle;
    setOutputs(newOutputs);
  };

  const getWavHeader = (options: {
    isFloat: boolean;
    numChannels: number;
    sampleRate: number;
    numFrames: number;
  }) => {
    const numFrames = options.numFrames;
    const numChannels = options.numChannels || 2;
    const sampleRate = options.sampleRate || 44100;
    const bytesPerSample = options.isFloat ? 4 : 2;
    const format = options.isFloat ? 3 : 1;

    const blockAlign = numChannels * bytesPerSample;
    const byteRate = sampleRate * blockAlign;
    const dataSize = numFrames * blockAlign;

    const buffer = new ArrayBuffer(44);
    const dv = new DataView(buffer);

    let p = 0;

    function writeString(s: string) {
      for (let i = 0; i < s.length; i++) {
        dv.setUint8(p + i, s.charCodeAt(i));
      }
      p += s.length;
    }

    function writeUint32(d: number) {
      dv.setUint32(p, d, true);
      p += 4;
    }

    function writeUint16(d: number) {
      dv.setUint16(p, d, true);
      p += 2;
    }

    writeString("RIFF"); // ChunkID
    writeUint32(dataSize + 36); // ChunkSize
    writeString("WAVE"); // Format
    writeString("fmt "); // Subchunk1ID
    writeUint32(16); // Subchunk1Size
    writeUint16(format); // AudioFormat https://i.stack.imgur.com/BuSmb.png
    writeUint16(numChannels); // NumChannels
    writeUint32(sampleRate); // SampleRate
    writeUint32(byteRate); // ByteRate
    writeUint16(blockAlign); // BlockAlign
    writeUint16(bytesPerSample * 8); // BitsPerSample
    writeString("data"); // Subchunk2ID
    writeUint32(dataSize); // Subchunk2Size

    return new Uint8Array(buffer);
  };

  // Returns Uint8Array of WAV bytes
  const getWavBytes = (
    buffer: ArrayBufferLike,
    options: { isFloat: boolean; numChannels: number; sampleRate: number }
  ) => {
    const type = options.isFloat ? Float32Array : Uint16Array;
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT;

    const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }));
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

    // prepend header, then add pcmBytes
    wavBytes.set(headerBytes, 0);
    wavBytes.set(new Uint8Array(buffer), headerBytes.length);

    return wavBytes;
  };

  const convertAudioBufferToBlobAndDownload = (
    audioBuffer: AudioBuffer,
    filename: string
  ) => {
    var channelData = [],
      totalLength = 0,
      channelLength = 0;

    for (var i = 0; i < audioBuffer.numberOfChannels; i++) {
      channelData.push(audioBuffer.getChannelData(i));
      totalLength += channelData[i].length;
      if (i === 0) channelLength = channelData[i].length;
    }

    const interleaved = new Float32Array(totalLength);

    for (
      let src = 0, dst = 0;
      src < channelLength;
      src++, dst += audioBuffer.numberOfChannels
    ) {
      for (var j = 0; j < audioBuffer.numberOfChannels; j++) {
        interleaved[dst + j] = channelData[j][src];
      }
    }

    const wavBytes = getWavBytes(interleaved.buffer, {
      isFloat: true,
      numChannels: audioBuffer.numberOfChannels,
      sampleRate: audioBuffer.sampleRate,
    });
    const wav = new Blob([wavBytes], { type: "audio/wav" });
    const newFilename = filename.replace(/\.[^/.]+$/, "");
    saveAs(wav, newFilename + ".wav");
    return wav;
  };

  const addOutput = async (file: File, data: any, blob: Blob): Promise<null> => {
    const audioContext = new AudioContext();
    try {
      const audioData = await file.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      setOutputs((prev) => {
        const newOutput: IOutput = {
          title: (currentModel.generatedAudioTitle ?? "Generated audio ") + (prev.length + 1).toString(),
          controls: currentModel.getOutputControls(data),
          color: getNextColor(prev),
          generatedAt: data.generatedAt,
          audioBuffer: audioBuffer,
          originalAudioBuffer: audioBuffer,
          duration: data.duration,
          blob: blob,
          offset: data.inputOffset ?? 0,
          added: true,
          model: currentModel.name,
          godMode: currentModel.godMode,
        };
        pauseAllPlayers();
        setInputs([...inputs, newOutput as any]);
        return [...prev, newOutput];
      });
      return null;
    } catch (error) {
      console.error("Error loading audio file:", error);
      return null;
    }
  };

  const getNextColor = (list: any[]) => {
    const colors = ["#DB3B72", "#DB8573", "#DB6149", "#DBA747"];
    return colors[list.length % colors.length];
  };

  const removeOutput = (index: number) => {
    const newOutputs = [...outputs];
    newOutputs.splice(index, 1);
    setOutputs(newOutputs);
  };

  const addOutputToInput = (index: number) => {
    const output: any = outputs[index];
    output.added = true;
    setInputs([...inputs, output]);
  };

  const contextValue = {
    outputs,
    addOutput,
    setOutputs,
    removeOutput,
    convertAudioBufferToBlobAndDownload,
    modifyOutputTitle,
    addOutputToInput,
    getNextColor,
    isGenerating,
    setIsGenerating,
    isTextInputMode,
    setIsTextInputMode,
  };

  return (
    <OutputContext.Provider value={contextValue}>
      {children}
    </OutputContext.Provider>
  );
};
