import { FC, useCallback, useContext, useEffect, useState } from "react";
import "./TracksListContainer.css";
import TracksListHeader from "./TracksListHeader";
import AudioTrack from "../AudioTrack/AudioTrack";
import {
  IInput,
  IInputContext,
  InputContext,
} from "../../contexts/InputContext";
import { useKeyPress } from "../../hooks/UseKeyPress";
import { IOutputContext, OutputContext } from "../../contexts/OutputContext";

interface TracksListContainerProps {
  title?: string;
  isGenerated?: boolean;
  icon?: any;
}

const TracksListContainer: FC<TracksListContainerProps> = ({
  title = "Inputs List",
  isGenerated = false,
  icon,
}) => {
  const { inputs, getAdded, getUnadded } = useContext(
    InputContext
  ) as IInputContext;
  const { isTextInputMode } = useContext(OutputContext) as IOutputContext;
  const [isFolded, setIsFolded] = useState<boolean>(true);
  const [audios, setAudios] = useState<IInput[]>([]);
  const [zoom, setZoom] = useState<number>(1.0);
  const maxZoom = 10.0;
  const minZoom = 1.0;

  const zoomOut = () => {
    if (Math.ceil(zoom) === minZoom) return;
    const newZoom = zoom  - 0.1;
    setZoom(newZoom);
  };

  const zoomIn = () => {
    if (Math.ceil(zoom) === maxZoom) return;
    const newZoom = zoom + 0.1;
    setZoom(newZoom);
  };

  useKeyPress(["+"], () => {
    if (isTextInputMode) return;
    zoomIn();
  }, [187]);

  useKeyPress(["-"], () => {
    if (isTextInputMode) return;
    zoomOut();
  }, [189]);

  const toggleFold = useCallback(() => {
    setIsFolded((prev) => !prev);
  }, []);

  useEffect(() => {
    setAudios(isGenerated ? getAdded(): getUnadded());
  }, [inputs, getAdded, getUnadded, isGenerated]);

  return (
    <div className="tracks-list-border-container">
      <div className="tracks-list-main-container">
        <TracksListHeader
          icon={icon}
          title={title}
          isFolded={isFolded}
          setIsFolded={toggleFold}
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          setZoom={setZoom}
        />
        {inputs.length === 0 && (
          <>
            <AudioTrack
              key={0}
              inputItem={{} as IInput}
              isGeneratedInput={isGenerated}
              index={0}
              isSectionFolded={true}
            />
          </>
        )}
        {audios.map((input, index) => { return (
          <AudioTrack
            key={index}
            inputItem={input}
            isGeneratedInput={isGenerated}
            index={inputs.indexOf(input)}
            isSectionFolded={isFolded}
            zoom={zoom}
            isMutedTrack={input.isMuted}
            isSoloedTrack={input.isSoloed}
          />
        )})}
      </div>
    </div>
  );
};

export default TracksListContainer;
