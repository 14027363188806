import { FC, useContext, useMemo, useState } from "react";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import sonyLogo from "../../assets/sony-logo.svg";
import "./Header.css";
import { isMobile } from "react-device-detect";
import BigRoundedButton from "../Buttons/BigRoundedButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelperTextContainer from "../UI/HelperTextContainer";
import Player from "../Player/Player";
import enterIcon from "../../assets/keyboard/enter-icon.svg";
import backspaceIcon from "../../assets/keyboard/backspace-icon.svg";
import spacebarIcon from "../../assets/keyboard/spacebar-icon.svg";
import horizentalArrowsIcon from "../../assets/keyboard/horizental-arrows-icon.svg";
import verticalArrowsIcon from "../../assets/keyboard/vertical-arrows-icon.svg";
import plusIcon from "../../assets/keyboard/plus-icon.svg";
import minusIcon from "../../assets/keyboard/minus-icon.svg";
import mLetterIcon from "../../assets/keyboard/m-letter-icon.svg";
import sLetterIcon from "../../assets/keyboard/s-letter-icon.svg";
import inpaintingIcon from "../../assets/keyboard/inpainting-icon.svg";
import GenericDropdownBasic from "../UI/GenericDropdown";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";

const appIsSingleModel = process.env.REACT_APP_SINGLE_MODEL === "true";

interface HeaderProps {
  state: string;
}

export const Header: FC<HeaderProps> = ({ state }) => {
  const [isHelperTextOpen, setIsHelperTextOpen] = useState(false);
  const { currentModel, setCurrentModel, models } = useContext(
    ModelContext
  ) as ModelContextProps;

  const helperText = useMemo(() => {
    return (
      currentModel.helper ?? (
        <div className="helper-text-main-container">
          <div className="helper-text-up-container">
            <strong>Diffusion BassNet</strong> is an AI tool that you can use to
            create bass lines. Rather than working from scratch, it is designed
            to create bass lines in response to any existing musical ideas you
            already have. Every bass line wil be different, but will generally
            fit to your existing musical material in terms of rhythm, tonality,
            and instrument sound.
            <br />
          </div>
          <div className="helper-text-down-container">
            <strong>KeyBoard Shortcuts</strong>
            <div style={{ display: "flex" }}>
              <div className="helper-text-down-box-container">
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="enter-icon"
                    icon={enterIcon}
                  />
                  New Bassline
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="3.6rem"
                    alt="backspace-icon"
                    icon={backspaceIcon}
                  />
                  Redo last bassline
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="3.6rem"
                    alt="spacebar-icon"
                    icon={spacebarIcon}
                  />
                  Play/Pause
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="horizental-arrow-icon"
                    icon={horizentalArrowsIcon}
                  />
                  Transform
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="vertical-arrow-icon"
                    icon={verticalArrowsIcon}
                  />
                  Guidance
                </div>
              </div>
              <div className="helper-text-down-box-container">
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="inpainting-icon"
                    icon={inpaintingIcon}
                  />
                  Inpainting
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="plus-icon"
                    icon={plusIcon}
                  />
                  Waveform zoom in
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="minus-icon"
                    icon={minusIcon}
                  />
                  Waveform zoom out
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="m-letter-icon"
                    icon={mLetterIcon}
                  />
                  Mute
                </div>
                <div className="helper-text-keyboard-container">
                  <SimpleSVGComponent
                    width="2rem"
                    alt="s-letter-icon"
                    icon={sLetterIcon}
                  />
                  Solo
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }, [currentModel]);

  return (
    <div className="header-main-container">
      <div className="header-sony-container">
        {" "}
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && <SimpleSVGComponent alt="Sony logo" width="4.5rem" icon={sonyLogo} />}
      </div>
      <div className="header-bassnet-container">
        <div className="header-logo-and-name-container">
          <div
            className="header-logo-background"
            style={{ backgroundColor: currentModel.color }}
          >
            <SimpleSVGComponent
              alt="bassnet-logo"
              width={isMobile ? "45%" : "45%"}
              icon={require(`../../assets/${currentModel.icon}`)}
            />
          </div>
          {appIsSingleModel ? (
            <SimpleSVGComponent
              alt="bassnet-logo-name"
              width={isMobile ? "100px" : "6vw"}
              icon={require(`../../assets/${currentModel.nameLogo}`)}
              style={{ marginLeft: "0.6vw" }}
            />
          ) : (
            <div style={{ width: "14vw", height: "50%", marginLeft: "0.3vw" }}>
              <GenericDropdownBasic
                currentSelectedItem={{
                  title: currentModel.name,
                  icon: require(`../../assets/${currentModel.nameLogo}`),
                }}
                // if model title ==== currentModel.name then don't put it in items
                items={models
                  .filter((model) => model.name !== currentModel.name)
                  .map((model) => {
                    return {
                      title: model.name,
                      icon: require(`../../assets/${model.nameLogo}`),
                    };
                  })}
                setItemCallback={(item) =>
                  setCurrentModel(
                    models.find((model) => model.name === item.title) ??
                      currentModel
                  )
                }
                position="bottom"
                isDetails={false}
                style={{ gap: "1vw", width: "10vw" }}
              />
            </div>
          )}
        </div>
        <Player />
        <div className="header-help-container">
          <BigRoundedButton
            icon={<HelpOutlineIcon />}
            text={isMobile ? "" : "HELP"}
            onClick={() => setIsHelperTextOpen((state) => !state)}
            isSvg={false}
            isLoading={false}
            height="50%"
            width={isMobile ? "4vw" : "5.5vw"}
          />
          {isHelperTextOpen && (
            <HelperTextContainer
              setIsOpen={setIsHelperTextOpen}
              text={helperText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
