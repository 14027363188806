import { FC, useContext, useEffect, useState } from "react";
import "./TransformContainer.css";
import HorizontalSliderContainer from "../HorizontalSlider/HorizontalSliderContainer";
import { useKeyPress } from "../../hooks/UseKeyPress";
import ModelContext, { ModelContextProps } from "../../contexts/ModelContext";
import { OutputContext, IOutputContext } from "../../contexts/OutputContext";

export interface TransformContainerProps {
  width?: string;
  height?: string;
  /**
   * @default 2
   */
  max?: number;
  /**
   * @default -2
   */
  min?: number;
  /**
   * @default 0.01
   */
  step?: number;
  containerName?: string;
  types?: string[];
  showGauge?: boolean;
  splitGauge?: boolean;
  value: number;
  setValue: (value: number, controlIndex?: number) => void;
  /**
   * Sets the number of dots for the slider gauge
   * this number is multiplied by 2 + 1 if splitGauge = true
   * @default 10
   */
  nbGaugeDots?: number;
  controlIndex?: number
}

const TransformContainer: FC<TransformContainerProps> = (props) => {
  const [value, setValue] = useState<number>(props.value);
  //const { setSDEdit } = useContext(RequestContext) as IRequestContext;
  const { currentModel } = useContext(ModelContext) as ModelContextProps;
  const { isTextInputMode } = useContext(OutputContext) as IOutputContext;

  useEffect(() => {
    props.setValue(value, props.controlIndex);
    //console.log("value:", value, props.value);
  }, [value, props]);

  const onChangeSlider = (value: number) => {
    // transform value is between -2 and 2, scale it to 0 and 1
    setValue(value);
    //setSDEdit((value + 2) / 4);
  };

  useKeyPress(
    ["ArrowLeft"],
    () => {
      if (isTextInputMode) return;
      if (props.splitGauge && value > (props.min ?? -2)) {
        setValue(value - 0.1);
        //setSDEdit((value - 0.1 + 2) / 4);
      }
    },
    [37]
  );

  useKeyPress(
    ["ArrowRight"],
    () => {
      if (isTextInputMode) return;
      if (props.splitGauge && value < (props.max ?? 2)) {
        setValue(value + 0.1);
        //setSDEdit((value + 0.1 + 2) / 4);
      }
    },
    [39]
  );

  return (
    <div
      className="transform-border-container"
      style={{ width: props.width, height: props.height }}
    >
      <div className="transform-main-container">
        {props.containerName && (
          <div className="transform-upperside">
            <div
              style={{ backgroundColor: currentModel.lighterColor }}
              className="transform-type-container"
            >
              {props.containerName}
            </div>
          </div>
        )}
        <div className="transform-content-container">
          <div className="transform-container-type-container">
            {props.types &&
              props.types.map((type, index) => (
                <div key={index} className="transform-type-name">
                  <h1>{type}</h1>
                  <p>|</p>
                </div>
              ))}
            {/* <div className="transform-type-name">
                            <h1>Bass input</h1>
                            <p>|</p>
                        </div>
                        <div className="transform-type-name">
                            <h1>Default</h1>
                            <p>|</p>
                        </div>
                        <div className="transform-type-name">
                            <h1>Music input</h1>
                            <p>|</p>
                        </div> */}
          </div>
          <HorizontalSliderContainer
            nbGaugeDots={props.nbGaugeDots}
            onChange={onChangeSlider}
            value={value}
            showGauge={props.showGauge}
            min={props.min}
            step={props.step}
            max={props.max}
            splitGauge={props.splitGauge}
          />
        </div>
      </div>
    </div>
  );
};

export default TransformContainer;
