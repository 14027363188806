import { FC } from 'react';

interface SimpleSVGComponentProps {
  width?: string;
  icon: any;
  height?: string;
  cursor?: any;
  alt: string;
  onClick?: any;
  rotate?: string;
  style?: React.CSSProperties
}

const SimpleSVGComponent: FC<SimpleSVGComponentProps> = ({
  width,
  icon,
  height,
  cursor,
  alt,
  onClick,
  rotate,
  style
}) => {
  const mergedStyle: React.CSSProperties = {
    width: width ? width : '100%',
    height: height ? height : '100%',
    cursor: cursor ? 'pointer' : 'inherit',
    transform: `rotate(${rotate ?? 'none'})`,
    ...style,
  };

  return (
    <>
      <img
        onClick={onClick && onClick}
        src={icon}
        style={mergedStyle}
        alt={alt}
      />
    </>
  );
};

export default SimpleSVGComponent;