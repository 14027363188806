import { Backdrop, Fade, Modal, Box } from "@mui/material";
import React, { ReactNode } from "react";
import { theme } from "../../constants/theme";

export interface GenericModalProps {
  open: boolean;
  handleModalClose: () => void;
  element: ReactNode;
  width? : number;
  height?: number;
  padding?: number;
}

const GenericModal: React.FC<GenericModalProps> = (props) => {
  const modalStyle = {
    position: "absolute" as "absolute",
    height: props.height,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.darkGrey,
    padding: props.padding ?? 3,
    filter: "drop-shadow(0px 0.2vw 0.4vw rgba(0, 0, 0, 0.25))",
    borderRadius: "0.4vw",
    gap: "1vw",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.open}>
        <Box sx={modalStyle}>
          {props.element}
        </Box>
      </Fade>
    </Modal>
  );
};

export default GenericModal;