import { FC, useEffect, useState } from "react";
import "./RotarySlider.css";
import RotarySliderGauge from "./RotarySliderGauge";
import DistanceTracker from "./DistanceTracker";

interface RotarySliderProps {
  value: number;
  onChange: (value: number) => void;
  size: number;
  showGauge?: boolean;
  showHand?: boolean;
  onChangeEnd: (value: number) => void;
  min?: number;
  max?: number;
  sliderAcceleration?: number;
}

const RotarySlider: FC<RotarySliderProps> = ({
  value,
  onChange,
  size,
  showGauge,
  showHand,
  onChangeEnd,
  min,
  max,
  sliderAcceleration,
}) => {
  const [rotation, setRotation] = useState(0);

  const [gaugeDots, setGaugeDots] = useState<JSX.Element[]>([]);

  const [isSliding, setIsSliding] = useState<boolean>(false);

  const isSafari =
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  // la formule a garder localValue * coef * (2.6 - 130) <= calcul de l'angle
  useEffect(() => {
    const minRotationAngle = -130;
    const maxRotationAngle = 160;
    const rotation = (value - (min ?? 0)) * (maxRotationAngle - minRotationAngle) / ((max ?? 2) - (min ?? 0)) + minRotationAngle; // Calculate the rotation angle for the marker
    setRotation(rotation);
  }, [value, max, min]);

  useEffect(() => {
    const dotsToFill = (value / 2) * 16;

    const dots = Array(16)
      .fill(null)
      .map((_, index) => (
        <svg
          key={`rotary-slider-gauge-dot-${index}`}
          style={{
            transform: `rotate(${
              index * (360 / 20)
            }deg) translate(46px) rotate(-${index * (360 / 20)}deg)`,
            fill: index <= dotsToFill ? `rgba(255, 255, 255, 1)` : "#424242",
            width: "4px",
            height: "4px",
            position: "absolute",
          }}
          viewBox="0 0 6 6"
        >
          <circle
            cx="2.71966"
            cy="3.31009"
            r="2.5"
            transform="rotate(4.46501 2.71966 3.31009)"
          />
        </svg>
      ));
    setGaugeDots(dots);
  }, [value]);

  return (
    <div
      className="rotary-slider-container"
      style={{
        width: `${40 * size}%`,
        height: `100%`,
        aspectRatio: "1/1",
      }}
    >
      {showGauge ? (
        <RotarySliderGauge
          value={value}
          onChange={onChange}
          size={size}
          max={max}
          min={min}
        />
      ) : (
        <div className={"rotary-slider-doted-gauge"}>{gaugeDots}</div>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
          alignItems: "center",
          borderRadius: "50%",
          justifyContent: "center",
          backgroundColor: "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: isSliding ? (isSafari ? "130%" : "700%") : "300%",
            opacity: "0",
            zIndex: isSliding ? "7" : "2",
            cursor: isSliding ? "" : "",
          }}
        >
          <DistanceTracker
            localValue={value}
            setIsSliding={setIsSliding}
            sliderAcceleration={sliderAcceleration ?? 0.02}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
            max={max}
            min={min}
          />
        </div>
      </div>
      <div className="rotary-slider-knob">
        <div
          className="rotary-slider-hand"
          style={{
            transform: `rotate(${rotation}deg)`,
            backgroundColor: showHand ? "white" : "none",
            border: showHand ? "1px solid black" : "none",
          }}
        >
          {showHand ? (
            <div></div>
          ) : (
            <div className="rotary-slider-hand-point" />
          )}
        </div>
      </div>
    </div>
  );
};

export default RotarySlider;
