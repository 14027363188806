import React, { createContext, useCallback, useState } from 'react';
import * as Tone from 'tone';

interface PlayerContextProps {
    children: React.ReactNode;
}

export interface IPlayerContext {
    players: Tone.Player[];
    setPlayers: React.Dispatch<React.SetStateAction<Tone.Player[]>>;
    soloForPlayer: Tone.Solo[];
    setSoloForPlayer: React.Dispatch<React.SetStateAction<Tone.Solo[]>>;
    soloPlayer: (index: number) => void;
    unSoloPlayer: (index: number) => void;
    mutePlayer: (index: number, mute: boolean) => void;
    soloIndex: number;
    setSoloIndex: React.Dispatch<React.SetStateAction<number>>;
    changePlayerVolume: (index: number, volume: number) => void;
    panners: Tone.Panner[];
    setPanners: React.Dispatch<React.SetStateAction<Tone.Panner[]>>;
    globalMute: boolean;
    setGlobalMute: React.Dispatch<React.SetStateAction<boolean>>;
    isPlaying: boolean;
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
    cleanUpPlayers: () => void;
    currentTime: number;
    setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
    playersMuteState: boolean[];
    setPlayersMuteState: React.Dispatch<React.SetStateAction<boolean[]>>;
    pauseAllPlayers: () => void;
}

export const PlayerContext = createContext<IPlayerContext | undefined>(undefined);

export const PlayerContextProvider = ({ children }: PlayerContextProps) => {
    const [players, setPlayers] = useState<Tone.Player[]>([]);
    const [soloForPlayer, setSoloForPlayer] = useState<Tone.Solo[]>([]);
    const [playersMuteState, setPlayersMuteState] = useState<boolean[]>([]);
    const [soloIndex, setSoloIndex] = useState<number>(-1000);
    const [currentTime, setCurrentTime] = useState<number>(0);

    const [panners, setPanners] = useState<Tone.Panner[]>([]);
    const [globalMute, setGlobalMute] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const cleanUpPlayers = () => {
        // clean up all players, panners and transport and soloForPlayer
        players.forEach((player) => {
          if (player.state === "started") {
            player.stop();
          }
          player.disconnect();
          player.dispose();
        });
        panners.forEach((panner) => {
          panner.disconnect();
          panner.dispose();
        });
        soloForPlayer.forEach((solo) => {
            solo.disconnect();
            solo.dispose();
        });
        Tone.Transport.stop();
        Tone.Transport.seconds = 0;
        Tone.Transport.cancel(0);
        setPlayers([]);
        setPanners([]);
        setSoloForPlayer([]);
        setSoloIndex(-1000);
        setPlayersMuteState([]);
    }

    const pauseAllPlayers = useCallback(() => {
        setIsPlaying(false);
        Tone.Transport.pause();
    }, []);

    const soloPlayer = useCallback((index: number) => {
        if (players.length === 0) return;
        const localPlayers = players;

        // save the current mute state of all players
        const currentMuteState = players.map(player => player.mute);
        setPlayersMuteState(currentMuteState);
        localPlayers.forEach((player, i) => {
            if (index === i) {
                player.mute = false;
            } else {
                player.mute = true;
            }
        });
        soloForPlayer[index].solo = true;
        setPlayers(localPlayers);
    }, [players, setPlayers, soloForPlayer, setPlayersMuteState]);

    const unSoloPlayer = useCallback((index: number) => {
        if (players.length === 0) return;
        const localPlayers = players;
        localPlayers.forEach((player, index) => {
            player.mute = playersMuteState[index];
        });
        soloForPlayer[index].solo = false;
        setPlayers(localPlayers);
    }, [players, setPlayers, soloForPlayer, playersMuteState]);

    const mutePlayer = useCallback((index: number, mute: boolean) => {
        if (players.length === 0) return;
        const localPlayers = players;
        // setPlayersMuteState((prev) => {
        //     const newState = [...prev];
        //     newState[index] = mute;
        //     return newState;
        // });
        localPlayers[index].mute = mute;
        setPlayers(localPlayers);
    }, [players, setPlayers]);

    const changePlayerVolume = (index: number, volume: number) => {
        const player = players[index];
        player.volume.value = volume;
        setPlayers(players);
    }

    const contextValue = {
        players,
        setPlayers,
        soloForPlayer,
        setSoloForPlayer,
        soloPlayer,
        unSoloPlayer,
        mutePlayer,
        soloIndex,
        setSoloIndex,
        changePlayerVolume,
        panners,
        setPanners,
        globalMute,
        setGlobalMute,
        isPlaying,
        setIsPlaying,
        cleanUpPlayers,
        currentTime,
        setCurrentTime,
        playersMuteState,
        setPlayersMuteState,
        pauseAllPlayers,
    }

    return (
        <PlayerContext.Provider value={contextValue}>
            {children}
        </PlayerContext.Provider>
    );
};
