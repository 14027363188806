import React, {FC, useRef, useState} from 'react';
interface DistanceTrackerProps {
  localValue: number;
  setIsSliding: (value: boolean) => void;
  sliderAcceleration: number;
  onChange: (value: number) => void;
  onChangeEnd: (value: number) => void;
  min?: number;
  max?: number;
}
const DistanceTracker: FC<DistanceTrackerProps> = ({
                                                     localValue,
                                                     setIsSliding,
                                                     sliderAcceleration,
                                                     onChange,
                                                     onChangeEnd,
                                                     max,
                                                     min
                                                   }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const handleStart = (clientY: number) => {
    setStartY(clientY);
    setIsSliding(true);
  };
  const handleEnd = () => {
    setStartY(null);
    setIsSliding(false);
    onChangeEnd(localValue);
  };
  const handleMove = (clientY: number) => {
    if (startY !== null) {
      const movement = clientY - startY;
      let sliderValue = localValue - movement * sliderAcceleration;
      sliderValue = Math.max(min ?? 0, Math.min(max ?? 2, sliderValue));
      setStartY(clientY);
      onChange(sliderValue);
    }
  };
  return (
    <div
      draggable={true}
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
      onDragStart={(event: any) => {
        event.dataTransfer.dropEffect = 'none';
        handleStart(event.clientY);
      }}
      onDragOver={(e: any) => {
        handleMove(e.clientY);
      }}
      onDragEnd={() => {
        handleEnd();
      }}
      onTouchStart={(event: any) => {
        handleStart(event.touches[0].clientY);
      }}
      onTouchMove={(event: any) => {
        handleMove(event.touches[0].clientY);
      }}
      onTouchEnd={() => {
        handleEnd();
      }}
    />
  );
};
export default DistanceTracker;
