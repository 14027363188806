import { GuidanceContainerProps } from "../components/GuidanceContainer/GuidanceContainer";
import { IModel, IControl } from "./IModel";
import { TransformContainerProps } from "../components/Transform/TransformContainer";
import { IDownloadMIDIOptions } from "../contexts/RequestContext";
import JSZip from "jszip";
import saveAs from "file-saver";
import socket from "../services/socketService";
import axios from "axios";
import { IOutput } from "../contexts/OutputContext";
import SimpleSVGComponent from "../components/UI/SimpleSVGComponent";
import enterIcon from "../assets/keyboard/enter-icon.svg";
import backspaceIcon from "../assets/keyboard/backspace-icon.svg";
import spacebarIcon from "../assets/keyboard/spacebar-icon.svg";
import horizentalArrowsIcon from "../assets/keyboard/horizental-arrows-icon.svg";
import verticalArrowsIcon from "../assets/keyboard/vertical-arrows-icon.svg";
import plusIcon from "../assets/keyboard/plus-icon.svg";
import minusIcon from "../assets/keyboard/minus-icon.svg";
import mLetterIcon from "../assets/keyboard/m-letter-icon.svg";
import sLetterIcon from "../assets/keyboard/s-letter-icon.svg";
import inpaintingIcon from "../assets/keyboard/inpainting-icon.svg";

class BassNetModel implements IModel {
  color: string;
  controls: IControl[];
  description: string;
  icon: string;
  lighterColor: string;
  name: string;
  nameLogo: string;
  fetchGenerate: (
    buffer: AudioBuffer,
    inpaint?: AudioBuffer,
    clamp?: number[],
    invertedInpaint?: boolean,
    originalBufferEnd?: number,
    inputOffset?: number
  ) => Promise<any>;
  fetchTranscribe: (
    outputs: IOutput[],
    info?: IDownloadMIDIOptions
  ) => Promise<void>;
  client_id: string;
  getOutputControls: (data: any) => any[];
  dragAndDropText?: string;
  generateButtonText?: string;
  generatedContainerName?: string;
  outputsContainerName?: string;
  waitingText?: string;
  helper?: JSX.Element;
  generatedAudioTitle?: string | undefined;
  godMode?: boolean | undefined;

  constructor() {
    this.fetchGenerate = this.fetchGenerateBassline;
    this.fetchTranscribe = this.fetchTranscribeBassline;
    this.color = "#A61932";
    this.lighterColor = "#B96365";
    this.description = "Generates basslines";
    this.icon = "bassnet-logo-icon.svg";
    this.nameLogo = "bassnet-1.0-logo-name.svg";
    this.name = "BassNet";
    this.client_id = "";
    this.dragAndDropText = "Drag and Drop an audio file without bassline";
    this.generateButtonText = "NEW BASSLINE";
    this.generatedContainerName = "Generated basslines";
    this.outputsContainerName = "BASSLINES";
    this.waitingText = "waiting for basslines...";
    this.helper = this.helperElement;
    this.generatedAudioTitle = "Generated bassline ";
    this.godMode = true;

    socket.on("client_id", (data: string) => {
      console.log("client id received:", data);
      this.client_id = data;
    });

    const transformSlider: IControl = {
      type: "HorizontalSlider",
      props: {
        types: ["Bass input", "Default", "Music input"],
        showGauge: true,
        containerName: "TRANSFORM",
        max: 1,
        min: 0,
        setValue: this.setTransformSliderValue,
        value: 0.8,
        splitGauge: true,
        nbGaugeDots: 13,
      } as TransformContainerProps,
      name: "sdedit_t",
    };
    const guidanceSlider: IControl = {
      type: "RotarySlider",
      props: {
        leftType: "Free",
        rightType: "Follow",
        containerName: "Guidance",
        showGauge: true,
        showHand: false,
        setValue: this.setGuidanceValue,
        value: 1.5,
        max: 5,
        min: -1,
        sliderAcceleration: 0.1,
      } as GuidanceContainerProps,
      name: "Guidance",
    };
    this.controls = [];
    this.controls.push(transformSlider, guidanceSlider);
    this.getOutputControls = this.getBasslineControls;
  }

  // The order is important here, it must be the same as the controlIndex/controls order
  getBasslineControls = (data: any) => {
    return [data.sdedit_t, data.guidance];
  };

  // You can also make only one function that set a value using controlIndex (see example in pianonet model)
  setTransformSliderValue = (value: number) => {
    // Update the value in BassNetModel when the slider value changes
    (this.controls[0].props as TransformContainerProps).value = value;
  };

  setGuidanceValue = (value: number) => {
    (this.controls[1].props as GuidanceContainerProps).value = value;
  };

  fetchGenerateBassline = async (
    buffer: AudioBuffer,
    inpaint?: AudioBuffer,
    clamp?: number[],
    invertedInpaint?: boolean,
    originalBufferEnd?: number,
    inputOffset?: number
  ) => {
    const info = {
      start: 0,
      end: buffer.duration,
      guidance: this.controls[1].props.value,
      batch_size: 1,
      sdedits: this.controls[0].props.value,
    };
    const data = new FormData();
    const cond_blob = new Blob([buffer.getChannelData(0)], {
      type: "audio/raw",
    });
    data.append("client_id", this.client_id);
    data.append("batch_size", "1");
    data.append("guidance", this.controls[1].props.value.toString());
    data.append("sdedit_t", this.controls[0].props.value.toString());
    data.append("info", JSON.stringify(info));
    data.append("cond", cond_blob, "buffer");
    if (inputOffset) {
      data.append("input_offset", inputOffset.toString());
    }
    // for inpaint mode
    if (clamp && inpaint) {
      data.append(
        "clamp",
        JSON.stringify(
          !invertedInpaint && clamp[0] !== 0
            ? [
                [0, clamp[0]],
                [clamp[1], buffer.duration],
              ]
            : invertedInpaint
              ? [clamp]
              : [[clamp[1], buffer.duration]]
        )
      );
      if (originalBufferEnd)
        data.append("original_buffer_end", originalBufferEnd.toString());
      const inp_blob = new Blob([inpaint.getChannelData(0)], {
        type: "audio/raw",
      });
      data.append("inpaint", inp_blob, "buffer");
    }
    try {
      let res = await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/generate`, data)
        .then(async (res) => {
          if (res.status !== 200) {
            console.error("Error generating bassline");
          }
          return res.data[0];
        });
      return res;
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.log(error.response.data);
      } else console.log("Error fetching file:", error);
      return null;
    }
  };

  fetchTranscribeBassline = async (
    outputs: IOutput[],
    info?: IDownloadMIDIOptions
  ) => {
    const zip = new JSZip();
    const requests = outputs.map(async (output) => {
      const formData = new FormData();
      formData.append("client_id", this.client_id);
      formData.append("info", JSON.stringify(info));
      formData.append("file", output.blob, "file");
      await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/transcribe`, formData)
        .then(async (res) => {
          if (res.status === 200) {
            const filepath = `${process.env.REACT_APP_API_ENDPOINT}/${res.data[0].url}`;
            const response = await fetch(filepath);
            const blob = await response.blob();
            zip.file(output.title + ".mid", blob);
          } else
            console.log(
              "Error while transcribing bassline ",
              output.title,
              res.status
            );
        });
    });
    await Promise.all(requests);
    await zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "basslines_mid.zip");
    });
  };

  helperElement = (
    <div className="helper-text-main-container">
      <div className="helper-text-up-container">
        <strong>Diffusion BassNet</strong> is an AI tool that you can use to
        create bass lines. Rather than working from scratch, it is designed to
        create bass lines in response to any existing musical ideas you already
        have. Every bass line wil be different, but will generally fit to your
        existing musical material in terms of rhythm, tonality, and instrument
        sound.
        <br />
      </div>
      <div className="helper-text-down-container">
        <strong>KeyBoard Shortcuts</strong>
        <div style={{ display: "flex" }}>
          <div className="helper-text-down-box-container">
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="enter-icon"
                icon={enterIcon}
              />
              New Bassline
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="3.6rem"
                alt="backspace-icon"
                icon={backspaceIcon}
              />
              Redo last bassline
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="3.6rem"
                alt="spacebar-icon"
                icon={spacebarIcon}
              />
              Play/Pause
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="horizental-arrow-icon"
                icon={horizentalArrowsIcon}
              />
              Transform
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="vertical-arrow-icon"
                icon={verticalArrowsIcon}
              />
              Guidance
            </div>
          </div>
          <div className="helper-text-down-box-container">
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="inpainting-icon"
                icon={inpaintingIcon}
              />
              Inpainting
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="plus-icon"
                icon={plusIcon}
              />
              Waveform zoom in
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="minus-icon"
                icon={minusIcon}
              />
              Waveform zoom out
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="m-letter-icon"
                icon={mLetterIcon}
              />
              Mute
            </div>
            <div className="helper-text-keyboard-container">
              <SimpleSVGComponent
                width="2rem"
                alt="s-letter-icon"
                icon={sLetterIcon}
              />
              Solo
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BassNetModel;
