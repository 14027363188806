import { useCallback, useEffect, useRef } from "react";

interface AudioPlayerProps {
  audioBuffer: AudioBuffer | null;
  onAudioEnd: () => void;
  setCurrentTime?: (currentTime: number) => void;
  currentTime: number;
}

const useAudioPlayer = ({
  audioBuffer,
  onAudioEnd,
  setCurrentTime,
  currentTime,
}: AudioPlayerProps) => {
  const audioContext = useRef<AudioContext | null>(null);
  const audioSource = useRef<AudioBufferSourceNode | null>(null);
  const tempCurrentTime = useRef<number | null>(null);

  const playAudio = useCallback(() => {
    if (
      audioBuffer &&
      audioContext.current &&
      currentTime < audioBuffer.duration &&
      currentTime === audioContext.current.currentTime
    ) {
      audioContext.current.resume();
      return;
    }
    if (!audioContext.current) {
      audioContext.current = new AudioContext();
    }

    audioSource.current = audioContext.current.createBufferSource();
    audioSource.current.buffer = audioBuffer;
    audioSource.current.connect(audioContext.current.destination);
    tempCurrentTime.current = currentTime;
    audioSource.current.start(0, currentTime);
  }, [audioBuffer, currentTime]);

  function pauseAudio() {
    audioContext.current?.suspend();
  }

  const stopAudio = useCallback(() => {
    if (audioSource.current && audioContext.current) {
      audioSource.current.stop();
      audioContext.current.close();
      audioContext.current = null;
    }
  }, []);

  useEffect(() => {
    if (audioBuffer && currentTime >= audioBuffer.duration && setCurrentTime) {
      stopAudio();
      setCurrentTime(0);
      onAudioEnd();
    }
  }, [currentTime, audioBuffer, onAudioEnd, setCurrentTime, stopAudio]);

  setInterval(() => {
    if (
      setCurrentTime &&
      audioContext.current &&
      audioContext.current.state === "running"
    ) {
      setCurrentTime(audioContext.current.currentTime + (tempCurrentTime.current ?? 0));
    }
  }, 40);

  useEffect(() => {
    return () => {
      if (audioSource.current) {
        audioSource.current.stop();
        audioSource.current.disconnect();
        audioSource.current = null;
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  return { playAudio, stopAudio, pauseAudio };
};

export default useAudioPlayer;
